// @import "../node_modules/@syncfusion/ej2-base/styles/material.css";
// @import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
// @import "../node_modules/@syncfusion/ej2-react-inputs/styles/material.css";

body {
  margin: 0;
  padding: 0;
}

.css-1wc848c-MuiFormHelperText-root {
  color: red !important;
}

.top-banner-txt-container {
  //padding-left: 70px !important;
  padding-bottom: 35px;
}

.banner-link-text {
  padding-right: 15px;
}

.span_front1 {
  font-size: 1.35rem !important;
  font-weight: 400 !important;
}

.nav-login {
  padding: 8px 25px;
  border-radius: 5px;
}

.login-item input::placeholder {
  color: rgb(150, 150, 150);
  font-weight: 100;
  font-size: 0.9rem;
}

.input-box input::placeholder {
  color: rgb(150, 150, 150);
  font-weight: 100;
  font-size: 0.9rem;
}

//#investor-page {
//  width: 99.5%;
//  height: calc(100vh - 80px);
//  overflow: auto;
//  margin: 0;
//}
.add_profile {
  cursor: pointer;
  display: flex;
  padding: 0px 20px;
  margin-top: 10px;

  .add_profile_h3 {
    flex: 2;
    font-weight: 400;
    margin-bottom: 5px;
  }

  .add_profile_icon {
    flex: 1;

    svg {
      float: right;
      margin-top: 3px;
    }
  }

  a {
    color: #888888 !important;
  }
}

.my_profile_h3 {
  font-weight: 400;
  margin: 5px 0px;
  padding: 5px;
}

.my_profile_h3:hover {
  background-color: #f8f9fa;
}

.dropdown_main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.top_my_profile {
  padding-left: 20px;

  span {
    font-size: 0.8rem;
    color: #0d6efd;
    font-weight: bolder;

    a {
      color: #000;
    }
  }
}

.dropdown-menu {
  width: 95% !important;
}

.add_your_profile {
  ul {
    padding-left: 0px;
    margin-bottom: 0px;
  }

  ul li {
    list-style: none;
    margin-bottom: 0px;
    margin-left: 20px;
    font-size: 0.9rem;
  }

  li {
    padding: 7px 0px;
  }

  li:hover {
    background-color: #f8f9fa;
  }

  a {
    color: black;
  }
}

.profile_detail_main {
  background-color: #fff;
  //height: calc(100vh - 90px);
  border-radius: 10px;
  padding: 20px 20px;
  margin: 0 20px;
}

.child_dropdown {
  border: 2px solid red;
}

// profile_detail
.profile_detail_main_h3 {
  font-weight: bold;
  font-size: 1.3rem;
  color: #09f;
}

.profile_detail_main_social {
  display: inline-block;

  ul li {
    display: inline-block;
    padding-right: 10px;
  }

  ul {
    margin: 0;
    padding-left: 0px !important;
  }

  .p_d_m_s_1 {
    font-size: 0.7rem;
    padding-right: 3px;
    font-weight: 100;
    color: #a3a3a3;
  }

  .p_d_m_s_2 {
    font-size: 0.7rem;
    color: #a3a3a3;
  }
}

.p_d {
  font-size: 0.9rem;
  font-weight: 500;
}

.profile_details {
  tr td:nth-child(1) {
    padding-right: 60px;
    font-weight: 500;
  }

  tr td {
    font-size: 0.9rem;
    padding-top: 10px;
  }
}

.header-title {
  h2 {
    font-size: 1.2rem;
    font-weight: 400;
  }

  span {
    color: #474747 !important;
  }
}

.personal-details {
  //padding: 15px !important;
  h3 {
    font-size: 1.2rem;
    font-weight: bolder;
    margin-bottom: 10px;
  }
}

.idea-form-parameter {
  margin-bottom: 15px !important;
}

.i_l_p {
  label {
    font-size: 1rem;
  }
}

.form-wrapper {
  padding: 10px 20px !important;
}

.image_uploader {
  background-color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  position: relative;

  img {
    height: 35px;
    width: 100%;
    object-fit: contain;
  }
}

.upload-btn label {
  // height: 35px !important;
  background-color: unset !important;
  box-shadow: unset !important;
}

.photo_upload {
  margin: 13px 8px 0px 3px !important;
}

.remove_img {
  position: absolute;
  border-radius: unset !important;
  right: -53px;
  top: 24px;
  display: inline-block;
  color: red;
  background: none;

  img {
    height: 24px;
    width: 100%;
    object-fit: contain;
  }
}

.refresh_img {
  position: absolute;
  right: -53px;
  top: 59px;
  border-radius: unset !important;
  /* background-color: #09f; */
  color: #0971dd;
  background: #fff;

  img {
    height: 24px;
    width: 100%;
    object-fit: contain;
  }
}

.upload__image-wrapper {
  // border: 1px solid black;
  position: relative !important;
  border-radius: 50%;
  display: inline-block;
  // margin-top: 6px;

  button {
    // border: none;
    border: 1px solid #dbdbdb;
    border-radius: 50%;

    img {
      height: 90px;
      width: 90px;
      object-fit: cover;
      border-radius: 50%;

      overflow: hidden;
    }
  }

  .camera__s {
    position: absolute;
    top: 62px;
    right: 31px;

    svg {
      font-size: 24px;
      color: #626262;

      &:hover {
        color: #fff;
        transition: 0.8s;
      }
    }
  }
}

#img__main_uploader {
  height: 90px;
  width: 90px;
  border: 2px solid rgb(201, 201, 201);
  border-radius: 50%;
  overflow: hidden;

  img {
    height: 90px;
    width: 90px;
    object-fit: cover;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    /* padding: 10
      px
      ; */
    overflow: hidden;
  }
}

.dzu-previewStatusContainer {
  width: 140px !important;
  position: absolute;
  right: 10px !important;
  bottom: 199px !important;
}

.idea-form-parameter {
  display: unset !important;
}

.e-control-wrapper {
  margin-top: 10px;
}

e-css {
  color: #299fcc !important;
  border-radius: 5px !important;
}

.feed-image-container {
  padding: 0 !important;

  img {
    height: 340px;
    width: 100%;

    object-fit: cover;
  }
}

.feed-body {
  padding: 5px 20px 5px 20px !important;
}

.idea_category span {
  background-color: #eceff5 !important;
  border-radius: 5px !important;
  padding: 7px 10px !important;
  color: #000 !important;
  font-size: 12px;
  font-weight: 400 !important;
}

.rcs-custom-scroll {
  min-height: 0;
  min-width: 0;
}

.rcs-custom-scroll .rcs-outer-container {
  overflow: hidden;
}

.rcs-custom-scroll .rcs-outer-container .rcs-positioning {
  position: relative;
}

.rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar {
  opacity: 1;
  transition-duration: 0.2s;
}

.rcs-custom-scroll .rcs-inner-container {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.rcs-custom-scroll .rcs-inner-container::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
  background-image: linear-gradient(
                  to bottom,
                  rgba(0, 0, 0, 0.2) 0%,
                  rgba(0, 0, 0, 0.05) 60%,
                  rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
  transition: height 0.1s ease-in;
  will-change: height;
}

.rcs-custom-scroll .rcs-inner-container.rcs-content-scrolled:after {
  height: 5px;
  transition: height 0.15s ease-out;
}

.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container {
  user-select: none;
}

.rcs-custom-scroll .rcs-custom-scrollbar {
  position: absolute;
  height: 100%;
  width: 6px;
  right: 3px;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.4s ease-out;
  padding: 6px 0;
  box-sizing: border-box;
  will-change: opacity;
  pointer-events: none;
}

.rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl {
  right: auto;
  left: 3px;
}

.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar {
  opacity: 1;
}

.rcs-custom-scroll .rcs-custom-scroll-handle {
  position: absolute;

  width: 100%;
  top: 0;
}

.rcs-custom-scroll .rcs-inner-handle {
  height: calc(100% - 12px);
  margin-top: 6px;
  background-color: rgba(78, 183, 245, 0.7);
  border-radius: 3px;
}

.react-tag-input {
  height: 60px !important;
}

.idea-detail {
  display: flex;

  .detail-item {
    flex: 1;
    display: flex;
    align-items: center;
    //padding: 10px 10px !important;
    margin-bottom: unset;
    /* background-color: #eceff5; */
    //border: 2px solid #eceff5 !important;
    background-color: unset !important;
  }

  .feed-counter {
    flex: 1;
    //padding-right: 36px;
    //border-top: 2px solid #eceff5;
    //border-bottom: 2px solid #eceff5;
    //border-left: 2px solid #eceff5;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    //padding: 3px 20px;
    border-radius: 5px 0px 0px 5px !important;
  }
}

.feed-wrapper {
  width: 100% !important;
  margin: 0 auto;
}

.feed-footer {
  padding: 0px 10px;
}

.feed-footer-btn {
  padding: 0px 10px 10px !important;
  justify-content: space-between;
  column-gap: 25px;
  display: flex;

  .idea-footer-left {
    flex: 1;
  }

  .idea-footer-right {
    flex: 1;
  }
}

.contact-btn {
  width: 30% !important;
  letter-spacing: unset !important;
}

.feed__location {
  //padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    color: #a3a3a3;
  }

  span {
    padding: 2px 5px 0px 7px !important;
    font-weight: 400;
    color: #838383;
  }
}

.idea-detail_top {
  display: flex;
  justify-content: space-between;

  .i_d1 {
    flex: 1;
    padding-right: 10px;
  }

  .i_d2 {
    flex: 17;
  }
}

.detail-container {
  .idea-detail-head {
    padding: 20px 20px 20px 20px;

    .feed__location {
      //padding: 0px;
      span {
        padding: 7px 10px !important;
      }
    }
  }

  .detail-item {
    padding: 5px 10px 10px 20px !important;
    margin-bottom: 5px !important;

    .detail-title {
      font-size: 1.1rem !important;
      font-weight: 500 !important;
    }
  }

  .idea-detail-img {
    img {
      height: 340px !important;
      width: 100% !important;
      object-fit: cover !important;
    }
  }

  .detail-item1 {
    padding: 10px 20px 10px 20px !important;
  }

  .details-text {
    text-align: justify;
    font-size: 0.9rem;
  }

  .details-blocks {
    padding: 10px 20px;

    label {
      background: #eceff5 !important;
      color: #000 !important;
      font-weight: 400;
    }

    .detail-item {
      padding: 10px !important;
      background-color: #ffffff !important;
      border: 2px solid #eceff5 !important;

      .location-block {
        .location-pill {
          label {
            border-radius: 5px !important;
          }
        }
      }

      .investment-label {
        border-radius: 5px !important;
        display: unset !important;
      }

      .investment-block {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
  }

  .idea-detail-hidden-box {
    padding: 10px 20px 10px 20px !important;

    .detail-item {
      padding: 10px !important;
    }
  }

  .idea-detail-footer {
    button {
      background-color: #0099ff !important;
      color: #fff !important;
    }

    button:hover {
      background-color: #a3a3a3 !important;
      color: rgb(255, 255, 255) !important;
      box-shadow: unset;
    }
  }

  .give-rating {
    button {
      border: 1px solid #d5d5d5;
      border-radius: 5px;
    }
  }
}

.idea-detail-hidden-box {
  grid-gap: 6px !important;
  gap: unset !important;

  .detail-item {
    border: unset !important;

    .detail-title {
      img {
        margin-right: 10px;
      }
    }
  }
}

.feed-topbar-container {
  border-radius: 5px !important;
}

.hidden-item::before {
  display: none !important;
}

.investor-detail-header {
  display: flex;

  .investor-back-btn {
    flex: 1;
  }

  .title_investor {
    flex: 7;
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: capitalize;
  }
}

.investor-detail-top {
  h4 {
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    margin-top: 15px;
    margin-bottom: 14px;
  }
}

.hidden-detail {
  border: unset !important;
  justify-content: unset !important;
  margin-bottom: 10px;
  padding: unset !important;

  svg {
    font-size: 0.5rem !important;
    padding-top: 2px !important;
  }

  p {
    font-size: 1.1rem !important;
    font-weight: 500 !important;
    margin-top: 10px;
  }
}

.investor-detail-row {
  h4 {
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    margin-bottom: 12px;
  }
}

.investor-description span {
  font-weight: 500 !important;
  font-size: 1rem !important;
}

.investor-interests span {
  font-weight: 500 !important;
  font-size: 1rem !important;
}

investor-detail-row {
  .location-list {
    label {
      font-size: 1.1rem !important;
      font-weight: 500 !important;
    }
  }
}

//.personal-detail-row {
//  margin-right: -69px !important;
//}
.i_desc {
  height: 66px !important;

  span {
    font-size: 1rem !important;
  }

  .i_desc_data {
    font-size: 0.8rem !important;
  }
}

.follow_investor {
  // flex: 1;
  border-radius: 5px;
  // background-color: #575757;

  padding: 8px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 62%; */
  color: #09f;
  height: 40.8px;
  transition: 0.2s;
  border: 1px solid #56abe3;

  &.outer.idea {
    height: 44px !important;

    .f_i_2 {
      font-size: 1rem !important;
    }
  }

  &.outer {
    width: 100%;
  }

  &.inner {
    height: 38px;

    .f_i_2 {
      font-size: 12px;
    }

    .f_i_1 {
      font-size: 16px;
    }
  }

  &.idea {
    width: unset;
    height: 38px;

    .f_i_2 {
      font-size: 12px;
    }

    .f_i_1 {
      font-size: 16px;
    }
  }

  &:hover {
    background-color: #fff;
    color: #56abe3;
    border: 1px solid #56abe3;
  }

  cursor: pointer;

  svg {
    padding: 2px;
    font-weight: 500;
  }

  .f_i_1 {
    padding-bottom: 2px;
  }

  .f_i_2 {
    padding-left: 5px;
    font-weight: 500;
    font-size: 14px;
  }
}

.socials-verify {
  // width: unset !important;

  li {
    padding: 0px 5px !important;
  }
}

.investment-size {
  display: flex;

  .inv_size1 {
    flex: 2;
    font-size: 0.8rem;
  }

  .inv_size2 {
    flex: 2;
    font-size: 0.8rem;
  }
}

.i_d_item b {
  font-size: 1.2rem !important;
  font-weight: 500;
//   padding-left: 15px;
}

.i_d_items b {
  font-size: 1.2rem !important;
  font-weight: 500;
}

hr {
  height: 0px !important;
}

#user-form {
  width: 90%;
  margin: 0 auto;

  .header-title {
    h2 {
      font-size: 1.4rem !important;
    }
  }

  .user-row {
    width: 90%;
    margin: 0 auto;
  }

  .personal-details {
    padding: 25px 40px 40px 40px !important;
  }
}

.e-upload {
  border-radius: 5px !important;
}

.title__wrap {
  display: flex;
}

.user__firstletter {
  position: relative;
  display: flex;
  border: 1px solid #09f;
  border-radius: 50%;
  align-items: center;
  // padding: 2px 10px;
  width: 30px;
  height: 30px;
  justify-content: center;
  cursor: pointer;
  background-color: #09f;
  color: #fff;
  text-transform: capitalize;

  &:hover {
    background-color: rgb(255, 255, 255);
    color: #09f;
    transition: 0.9s;
  }

  &.large__icon {
    font-size: 2rem;
    font-weight: 500;
    height: 90px;
    width: 90px;
    /* display: flex; */
    justify-content: center;
    padding: 0;
    background-color: rgb(255, 255, 255);
    color: #09f;
    border-width: 2px;
  }
}

#idea___form {
  margin: 15px 0;

  .form-btn {
    input {
      width: 20%;
      background-color: #3c9ef4;
      color: #fff;

      &:hover {
        background-color: #dfdfdf;
        color: #000;
        transition: 0.9s;
      }
    }
  }
}

#idea_selector {
  .MuiSelect-root {
    border: 1.5px solid #cccc;
    width: 43%;
    height: 46px;
  }

  label {
    display: block !important;
  }

  em {
    font-style: normal;
    color: #646464cc;
  }
}

.link__profile {
  display: flex;
  align-content: space-between;
  column-gap: 20px;
  border: 1px solid #cdcdcd;
  padding: 15px 8px 15px 8px;
  border-radius: 5px;

  label {
    font-size: 0.9rem;
  }

  .l_p_1 {
    flex: 1;
  }

  .l_p_2 {
    flex: 2;
  }

  .l_p_3 {
    padding-top: 33px;

    button {
      height: 40px;
      width: 100px;
      border-radius: 5px;
      background-color: #a3a3a3;
      color: #fff;
      border: none;
    }
  }
}

.dashboard-investor-detail {
  width: 97% !important;
  margin: 0 auto !important;
}

.td_investor_title {
  font-weight: bold !important;
}

.td_investor_title1 {
  font-weight: bold !important;
}

.td_investor_value {
  width: 30vw !important;
}

.cross_small_sidebar {
  svg {
    display: none;
  }
}

.res_show_table {
  display: none;
}

.res_table {
  display: table-cell;
}

.table-contents {
  overflow-x: auto;
  height: calc(100vh - 260px);
}

.p_d__m {
  padding: 20px 20px !important;
  margin: 0 20px !important;
}

.error-msg {
  height: 45px;
  border-radius: 5px;
  background-color: #fedee2;
  color: #ff4545;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .svg_cross {
    svg {
      height: 20px;
    }
  }
}

.sign-up-error {
  float: unset !important;
  font-size: 0.8rem;
  color: red;
}

.input-box label {
  margin-bottom: 0;
}

.signup_top_msg_box {
  // border: 1px solid #1f94d9;
  // border-radius: 5px;
  // padding: 6px 10px;
  margin-bottom: 8px;
}

.signup_msg {
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 300;
  /* color: #bcbcbc; */
  padding: 3px 0px;
}

.screening_wrap {
  padding: 0px 15px;
  margin: 20px 0;

  .header_main {
    background-color: #09f;
    border-radius: 5px;
    margin-bottom: 1rem;
    display: flex;
    padding: 10px;

    .text {
      flex: 1;
      color: #fff;
    }

    .back {
      align-self: flex-end;
      color: #fff;
      cursor: pointer;

      svg {
        margin-top: -2px;
      }
    }
  }

  .personal_detail_wrap {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      border-radius: 5px 5px 0px 0px;
      border: 1px solid #d4d4d4;
      background-color: #f2f2f2;

      h3 {
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 0px;
      }
    }

    .body_flex {
      padding: 10px 0px;
      display: flex;
      border: 1px solid #cfcfcf;
      margin-bottom: 1rem;
      background-color: #fff;
      border-top: 0px;

      .user_image {
        flex: 1;
        align-items: center;
        display: flex;
        justify-content: center;

        img {
          height: 200px;
          width: 200px;
          object-fit: contain;
        }
      }

      .detail_table {
        flex: 2;
        display: flex;
        align-items: center;

        tr {
          td {
            font-size: 1rem;
          }

          .td_table {
            font-weight: 500;
            padding-right: 30px;
            line-height: 30px;
          }
        }
      }
    }
  }

  .business_detail_flex {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;

    .idea__detail {
      textarea {
        width: 100%;
        border: 1px solid #d6d6d6ee;
        border-radius: 5px;
      }
    }
  }

  .g_1 {
    grid-area: myinvestment;
  }

  .g_2 {
    grid-area: invest_req;
  }

  .g_3 {
    grid-area: plan;
  }

  .g_4 {
    grid-area: study;
  }

  .my_investment {
    display: grid;
    grid-template-areas:
      "myinvestment myinvestment invest_req invest_req "
      "plan plan study study";
    grid-gap: 20px;

    .g_3,
    .g_4 {
      textarea {
        width: 100%;
        border: 1px solid #d6d6d6ee;
        border-radius: 5px;
      }
    }
  }

  .end_buttons {
    margin: 10px 0px;
    display: flex;
    column-gap: 20px;
    justify-content: center;

    //.button2 {
    //  //flex: 1;
    //  button {
    //    background-color: #fff;
    //    color: #db0000ee;
    //    border: 1px solid #db0000ee;
    //    &:hover {
    //      background-color: #db0000ee;
    //      color: #fff;
    //      transition: 0.4s;
    //    }
    //  }
    //}
    button {
      padding: 10px 10px;
      border-radius: 5px;
      font-size: 0.9rem;
      min-width: 200px;
      //height: 45px;
    }

    .button1 button:hover {
      color: #2196f3;
      border: 1px solid #09f;
      transition: 0.4s;
    }

    .primary-btn {
      background: #09f;
      color: #fff;
    }

    .warning-btn {
      background: #f5c255;
      color: #fff;
    }

    .danger-btn {
      color: #fff;
      background: #f55555;
    }
  }

  //main div ends
}

.p_p {
  padding: 20px 0px !important;
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)) !important;
}

#idea_selector input {
  height: 35px !important;
}

.css-1tm81ay {
  width: 100% !important;
}

.detail__flex_p {
  display: flex;

  .profile_detail_main_social {
    flex: 23;
  }

  .d_btn {
    flex: 1;
    padding-left: 10px;
  }
}

.table-contents_tabs {
  height: 67vh !important;
}

.table-wrapper_tabs {
  height: calc(100vh - 175px) !important;
}

.table-wrap-final {
  height: calc(100vh - 86px) !important;
}

#scheduler {
  width: 65% !important;

  .form-header h4 {
    font-size: 1.4rem;
    font-weight: 500;
  }

  .modal-header button {
    margin-top: 2px;
  }

  .modal-footer button {
    margin: 0 auto;
    width: 25%;
    border-radius: 5px;
  }

  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100% !important;

    label {
      color: #468fea;
    }
  }
}

.css-1l7g7dz-MuiGrid-root {
  margin-left: 0px;
}

.e-upload .e-upload-files {
  max-height: 92px !important;
  overflow-y: auto !important;
}

.t__c {
  height: unset !important;
}

.p_d__m_remarks {
  padding: 5px 20px;
}

.btn_remarks {
  padding: 5px !important;
  border: 1px solid #acacac !important;
  border-radius: 5px !important;
  margin: 0 !important;
  box-shadow: none !important;
}

.select_remarks select {
  border: 1px solid rgb(206, 206, 206);
  height: 35px;
  width: 150px;
  border-radius: 4px;
}

.volatile {
  span {
    margin-left: 10px;
  }
}

.view_remarks_eye {
  margin-left: 10px;
  cursor: pointer;

  svg {
    color: #09f;
  }

  span {
    color: #09f;
    font-size: 0.8rem;
  }
}

.a_r tbody tr {
  height: 47px !important;
}

/* * {
  font-family: "Open Sans", sans-serif;
} */
body {
  background-color: #eceff5 !important;
  font-weight: 400 !important;
}

a {
  text-decoration: none !important;
}

.fa-star {
  color: #fdcc0d;
}

.fa-map-marker-alt {
  color: orange;
}

.fa-bookmark {
  color: blue;
}

.fa-heart {
  color: red;
}

#logo {
  color: #000;
  display: flex;
  align-items: center;
}

#footer {
  height: auto;
  background: #eceff5;
}


.logoImg {
  border-radius: 50%;
}

a {
  text-decoration: none;
}

.compulsory-field {
  color: #ff574f;
}

.navHead {
  display: flex;
  justify-content: space-between;
  background-color: #eceff5;
}

.navHeadMenu {
  background-color: #eceff5 !important;
}

.sticky-top {
  top: -1px;
}

.searchBtn {
  position: relative;
  left: -35px;
  color: #343a40;
}

.login {
  display: flex;
  justify-content: space-around;
}

.searchBox {
  border-radius: 50px;
}

.navbar-light .navbar-nav .nav-link {
  /* color: #fff !important; */
}

.navbar-nav > .nav-item > .nav-link {
  /* color: #fff !important; */
}

.navbar-toggler {
  position: absolute;
  top: -50px;
  left: 85%;
  border: none;
  padding: 0;
}

.mainBanner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 500px;
  /* background-image: url("banner1.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0;
}

.cardImg {
  height: 230px;
}

.list-group-item {
  background: #eceff5 !important;
  /* color: white; */
  border: none !important;
  padding: 0.25rem 1.25rem;
  font-weight: 300;
  padding-left: 3.25rem;
}

.navbar-expand-lg {
  flex-flow: row nowrap;
  justify-content: space-between !important;
}

.box {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  font-size: 14px;
}

.card-titles {
  margin: 10px 0px 0px 20px;
}

.card-info {
  display: flex;
  justify-content: space-between;
  font-size: small;
  padding: 0;
  line-height: 1rem;
}

.rating-location {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.rating-location-icon {
  padding-right: 5px;
}

.investment-val {
  background: papayawhip;
  font-weight: 500;
  border-radius: 10px;
  padding: 0px 10px;
  margin-top: 5px;
}

.carousel-control-prev,
.carousel-control-next {
  color: black;
  background: black;
  opacity: 10%;
  height: 30px;
  width: 20px;
  top: 120px;
}

.card-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.idea-carousel {
  margin-bottom: 2px;
}

/* dashboard */
.dashboard-ui {
  display: flex;
  justify-content: start;
}

.dashboard-content {
  margin-top: 20px;
}

.mainpage {
  /* padding-bottom: 50px; */
  background-color: #eceff5;
}

.spinner {
  display: flex;
  justify-content: center;
}

#dropdown .btn {
  color: #586a7c;
  text-transform: uppercase;
  word-wrap: break-word;
  white-space: normal;
  cursor: pointer;
  border: 0;
  border-radius: 5px;
  box-shadow: unset;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 10px 10px;
  font-size: 0.81rem;
  width: 97%;
}

#dropdown .btn-secondary.dropdown-toggle {
  background-color: #fff !important;
  border: 1px solid #eceff5;
}

#nav-profile-menu .btn {
  background-color: #eceff5;
  border-radius: 50%;
  padding: 0;
  box-shadow: unset;
}

#nav-profile-menu .btn:hover {
  box-shadow: none;
}

#nav-profile-menu .dropdown-menu {
  min-width: 250px !important;
  position: absolute !important;
  right: 0;
  left: unset;
  box-shadow: 0 1px 5px 0 #000;
  border: 0;
  transform: translate3d(-175px, 45px, 0px) !important;
}

#nav-more-menu .btn {
  background-color: #eceff5;
  border-radius: 50%;
  padding: 0;
  box-shadow: unset;
}

#nav-more-menu .btn:hover {
  box-shadow: none;
}

#nav-more-menu .dropdown-menu {
  position: absolute !important;
  right: 0;
  left: unset;
  box-shadow: 0 1px 5px 0 #000;
  border: 0;
  transform: translate3d(-114px, 43px, 0px) !important;
}

#nav-more-menu .btn-secondary {
  background-color: unset !important;
}

#notification .btn {
  background-color: #eceff5;
  border-radius: 50%;
  padding: 0;
  box-shadow: unset;
}

#notification .btn:hover {
  box-shadow: none;
}

#notification .dropdown-menu {
  position: absolute !important;
  right: 0;
  left: unset;
  box-shadow: 0 1px 5px 0 #000;
  border: 0;
  transform: translate3d(-223px, 53px, 0px) !important;
  width: 300px !important;
}

#notification .btn-secondary {
  background-color: unset !important;
}

/*.css-168o85v-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input{*/
/*  padding: 5px !important;*/
/*}*/

/*.css-1txnhvm{*/
/*  position: relative;*/
/*}*/

/*.css-1k181zl{*/
/*  width: 100% !important;*/
/*}*/

.custom-mui-input .MuiTextField-root {
  width: 100%;
  margin: 10px 0;
  /*padding: 10px !important;*/
}

.custom-mui-input .MuiInputLabel-outlined {
  background-color: #fff;
  padding: 0 4px;
}

.custom-mui-input .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  top: 0 !important;
}

.custom-mui-select .PrivateNotchedOutline-root-1,
.PrivateNotchedOutline-root-5 {
  top: 10px !important;
  bottom: 10px !important;
}


.PrivateNotchedOutline-root-1,
.PrivateNotchedOutline-root-5 {
  top: 0 !important;
}

/*.investor_gender_select .PrivateNotchedOutline-root-1,*/
/*.PrivateNotchedOutline-root-5 {*/
/*  top: 0 !important;*/
/*  bottom: 5px !important;*/
/*}*/

.custom-mui-input .row {
  margin-bottom: 5px;
}

.MuiMenu-list {
  z-index: 99999999 !important;
}

.img-responsive {
  max-width: 100%;
}

.pdf-modal {
  width: 900px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.range-slider input {
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  left: 0;
  width: 100%;
  outline: none;
  height: 18px;
  margin: 0;
  padding: 0;
}

.range-slider input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.range-slider input[type="range"] {
  -webkit-appearance: none;
  top: -5px;
  pointer-events: all;
  border: 2px solid #ccd2db;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
  z-index: 1;
  outline: 0;
  height: 15px;
  width: 15px;
}

input[type="range"] {
  background-color: transparent;
}

input[type="range"]::-webkit-slider-runnable-track {
  background-color: transparent;
  height: 6px;
  border-radius: 13px;
  border: 1px solid #edeef0;
}

/* Styles for Firefox */
.range-slider input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;

  pointer-events: all;
  border: 2px solid #ccd2db;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
  z-index: 100;
  outline: 0;
  height: 12px;
  width: 12px;
}

.range-slider input[type="range"]::-moz-range-track {
  position: relative;
  z-index: -1;
  background: none transparent;
  height: 6px;
  border-radius: 13px;
  border: 1px solid #edeef0;
}

/* extra fixes for Firefox */
.range-slider input[type="range"]:last-of-type::-moz-range-track {
  -moz-appearance: none;
  background: none transparent;
  border: 0;
}

.range-slider input[type="range"]::-moz-focus-outer {
  border: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.MuiButtonBase-root{
  padding:2px 15px;
}
.e-css{
  margin-right: 0.07rem;
  margin-left: 0.07rem;
  margin-bottom: 0.5rem;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  outline: none;
  &:hover, &:focus{
    text-decoration: none;
  }
}

@mixin button($color, $backgroundColor, $borderColor){
  color: $color;
  background-color: $backgroundColor;
  border-color: $borderColor;
}

//---------------Style of each button-------------------
.e-css{
   @include button(#fff, #007bff, #007bff);
  &:hover{
    @include button(#fff, #0069d9, #0069d9);
  }
  &:focus{
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }
}
.e-css-secondary{
   @include button(#fff, #6c757d, #6c757d);
  &:hover{
    @include button(#fff, #5a6268, #5a6268);
  }
  &:focus{
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  }
}
.e-css-success{
  @include button(#fff, #28a745, #28a745);
  &:hover{
    @include button(#fff, #218838, #218838);
  }
  &:focus{
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
}
.e-css-danger{
  @include button(#fff, #dc3545, #dc3545);
  &:hover{
    @include button(#fff, #c82333, #c82333);
  }
  &:focus{
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }
}
.e-css-warning{
  @include button(#212529, #ffc107, #ffc107);
  &:hover{
    @include button(#212529, #e0a800, #e0a800);
  }
  &:focus{
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }
}
.e-css-info{
  @include button(#fff, #17a2b8, #17a2b8);
  &:hover{
    @include button(#fff, #138496, #138496);
  }
  &:focus{
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }
}
.e-css-light{
  @include button(#212529, #f8f9fa, #f8f9fa);
  &:hover{
    @include button(#212529, #e2e6ea, #e2e6ea);
  }
  &:focus{
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }
}
.e-css-dark{
  @include button(#fff, #343a40, #343a40);
  &:hover{
    @include button(#fff, #23272b, #23272b);
  }
  &:focus{
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  }
}
.e-css-link{
  font-weight: 400;
  @include button(#007bff, transparent, transparent);
  &:hover{
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
  }
}
img{max-width: 100%;}
button.MuiTab-textColorPrimary {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  max-width: 360px;
  min-width: 90px;
  position: relative;
  min-height: 48px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 12px 16px;
  overflow: hidden;
  white-space: normal;
  text-align: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.6);
}

//mobile devices input background color
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
	box-shadow: 0 0 0 30px white inset !important;
}