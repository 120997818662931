/*.investor-container {*/
/*  display: grid;*/
/*  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));*/
/*  gap: 20px;*/
/*  place-items: flex-start;*/
/*}*/
#investor-feed .investor-feed-wrapper {
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
    box-shadow: 0 1px 5px rgb(0 0 0 / 15%);
    margin-bottom: 20px;
}

.investor-container {
    display: flex;
    padding: 10px 0px 20px 0px;
}

.investor-detail-block {
    border-radius: 5px;
    padding: 20px;
    border: 1px solid #eceff5;
    max-width: 250px;

    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
}

.investor-detail-block:hover {
    background-color: #fff;
    box-shadow: 0px 4px 6px 1px rgb(0 0 0 / 5%);
    transition: 0.3s ease-in all;
}

.investor-header {
    display: flex;
    justify-content: center;
}

.investor-img {
    border-radius: 50%;
    object-fit: cover;
}

.investor-detail {
    display: flex;
    justify-content: left;
    flex-direction: column;
    padding: 0;
    align-items: left;
    margin: 2px 0;
}

.investor-name {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

.user-info {
    display: flex;
    flex-direction: column;
    font-size: 12px;
}

.user-info label {
    margin-top: 10px;
}

.investor-detail-footer .btn-item button {
    border: none;
    padding: 10px;
    border-radius: 5px;
}

.investor-detail-footer .btn-item button:hover {
    color: #fff;
    background-color: #0099ff;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
}

.investor-btn {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.investor-detail-left {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex: 1;
    border-right: 3px solid #eceff5;
    padding: 0 20px;
}

.investor-left-top {
    width: 100%;
    margin-top: 5px;
}

.investor-status {
    position: absolute;
    padding: 5px;
    background-color: #34ce57;
    border-radius: 50%;
    top: 15px;
    left: 17px;
}

.investor-detail li {
    list-style: none;
}

.socials-verify {
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2px 0;
    width: 100%;
    background-color: #eceff5;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 5px;
}

.s_v_flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.investor-detail-img {
    margin-top: 20px;
}

.investor-detail-item {
    font-size: 16px;
}

.investor-type {
    text-align: left;
    padding-top: 5px;
}

.investor-type p {
    margin: 0;
}

.investment-left-footer {
    width: 100%;
}

.i_size {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 2px solid #eceff5;
    padding: 10px;
    border-radius: 5px;
}

.investor-detail-right {
    flex: 2;
}

@media(min-width: 1000px){
	.investor-detail-right{
		width: 400px;
	}
}

.investor-right-top {
    display: flex;
    flex-direction: column;
}

.investor-description,
.investor-interests {
    font-size: 12px;
    height: 55px;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 10px;
    text-align: justify;
}

.investor-description,
.investor-interests p {
    font-size: 0.9rem;
    margin-top: 5px;
}

.investment-size {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    border: 2px solid #eceff5;
    padding: 10px;
    border-radius: 5px;
}

.investment-size p {
    font-size: 1rem !important;
    font-weight: 500;
    margin-bottom: 5px;
}

.investment-size span {
    font-size: 12px;
    border-radius: 5px;
    background: #eceff5;
    padding: 5px;
    width: 100%;
    text-align: center;
}

/*.investor-detail-right .location-lists::after {*/
/*  content: "+2 more";*/
/*  font-size: 12px;*/
/*}*/

.investor-detail-right .category-list,
.investor-detail-right .location-list {
    display: flex;
    margin: 10px 10px;
    align-items: center;
    justify-content: flex-start;
}

.investor-description span,
.investor-interests span {
    font-weight: 400;
}

.investor-detail-right .category-list label,
.investor-detail-right .location-list label {
    font-size: 12px;
    font-weight: 500;
    margin: unset;
}

.investor-detail-right .category-lists span,
.investor-detail-right .location-lists span {
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #eceff5;
}

.investor-feed-footer {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
    margin-top: 20px;
    column-gap: 10px;
}

.investor-feed-footer .btn-item {
    width: 100%;
}

.investor-feed-footer button {
    width: 100%;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    background-color: #09f;
    color: #fff;
    padding: 10px;
}

.investor-feed-footer button:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
}
.social-media-icons{
    list-style: none;
    display: flex;
    width: 100%;;
	padding-left: inherit;
	justify-content: center;
}
.social-media-icons li{display: inline-flex;padding:3px 20px;}
@media screen and (max-width: 500px) {

    .investor-container {
        flex-direction: column;
    }

    .investor-feed-footer {
        flex-direction: column;
    }

    .investor-detail-right .category-list,
    .investor-detail-right .location-list {
        display: flex;
        align-items: unset;
        flex-direction: column;
    }

    .category-list .category-lists,
    .location-list .location-lists {
        margin-left: 0;
    }

    .investment-size {
        margin-bottom: 10px;
    }

    .investor-feed-footer .feed-btn {
        margin-top: 15px;
    }

    .investor-detail-right .category-list label,
    .investor-detail-right .location-list label {
        font-size: 16px;

    }


}
