.login-head a:hover {
    text-decoration: none;
}

.login-nav {
    background-color: #eceff5;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-page-container {
    display: flex;
    justify-content: space-around;
    background-color: #eceff5;
    height: calc(100vh - 110px);
}

.login-left {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    align-items: center;
}

.login-left .lamp {
    height: calc(200px + 1.5vw);
    width: auto;
}

.login-left .man {
    height: auto;
    width: calc(200px + 1.5vw);
}

.login-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 300px;
    margin: 40px 0;
}

.loginImg {
    object-fit: contain;
}

/*.socials {*/
/*    display: flex;*/

/*    align-items: center;*/
/*    background-color: #f2f3f5;*/
/*    border-radius: 5px;*/
/*    padding: 8px 20px;*/
/*    margin-bottom: 20px;*/
/*    cursor: pointer;*/
/*}*/
/*.socials:hover {*/
/*    background-color: #eceff5;*/
/*}*/

/*.socials-logo {*/
/*    font-size: 20px;*/
/*    margin-right: 20px;*/
/*}*/

/*.socials-txt {*/
/*    color: #333;*/
/*    display: inline-block;*/
/*    max-width: 100%;*/
/*    overflow: hidden;*/
/*    text-overflow: ellipsis;*/
/*    white-space: nowrap;*/
/*    font-weight: 300;*/
/*}*/

.login-divider {
    display: flex;
    justify-content: center;
    color: #abb6c3;
    font-size: 14px;
}

.login-form {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 5px 5px rgb(0 0 0 / 15%);
    min-width: 400px;
}

.login-head {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.loginHead {
    font-size: 20px;
    font-weight: 600;
    color: #203a5c;
    padding: 20px 0;
}

.loginSubHead {
    color: #203a5c;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
}

.login-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.login-password-txt {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.login-form-title {
    color: #344c6b;
    font-size: 14px;
    margin-bottom: 5px;
}

.login-item input {
    border-radius: 3px;
    outline: none;
    background-color: #fff;
    padding: 5px 20px 5px 10px;
    border: 1px solid #abb6c3;
}

.login-item input[type="text"] {
    background-color: #fff;
}

.remember {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-top: 10px;
}

.login-checkbox {
    margin-right: 10px;
    width: unset;
}

.rememberMe {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.forgotPwd {
    color: #6394c6;
    font-size: 14px;
    cursor: pointer;
}

.forgotPwd:hover {
    color: #2d86fc;
}

.login-item button {
    padding: 10px 0;

    background-color: #6394c6;
    border: none;
    color: #fff;
    cursor: pointer;
}

.login-item button:hover {
    background-color: #2d86fc;
    box-shadow: 0 5px 5px rgb(0 0 0 / 15%);
}

.noAcc {
    color: #203a5c;
    font-size: 14px;
    display: flex;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: unset;
}

.registerTxt {
    color: #6394c6;
    font-size: 14px;
    cursor: pointer;
}

.registerTxt:hover {
    color: #2d86fc;
}

.login-img-container {
    object-fit: contain;
}

.loginSubHead button {
    background-color: #fff;
    border: none;
}

.login-right .logo-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.success-msg {
    background-color: #5cd08d;
    color: white;
    font-size: 14px;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    column-gap: 10px;
    margin-bottom: 10px;
}

.login-body .login-form-group {
    width: 100%;
    margin-bottom: 20px;
}

@media screen and (max-width: 560px) {
    .login-page-container {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 155px);
    }

    .login-form {
        padding: 20px 20px;
        margin: 0 10px 20px 10px;
    }

    .login-left {
        display: none;
    }

    .login-form {
        min-width: unset;
    }
}
