.profile_detail_main .personal-details {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 40px;
}

.profile_detail_main .idea-form-parameter {
    width: 100%;
}

.profile_detail_main .idea-parameter {
    width: 100%;
    column-gap: 40px;
}

.rejected-msg-block {
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: #f55555;
    color: #fff;
    padding: 10px;
    column-gap: 15px;
    justify-content: space-between;
}

.rejected-msg-block .rejected-msg,
.review-msg-block .rejected-msg {
    display: flex;
    flex-direction: column;
}

.review-msg-block {
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: #f5c255;
    color: #fff;
    padding: 10px;
    column-gap: 15px;
    justify-content: space-between;
}


@media only screen and (max-width: 486px) {
    .profile_detail_main .personal-details {
        grid-template-columns: auto;
    }

    .profile_detail_main.idea-parameter .idea-form-parameter input {
        width: 100% !important;
    }
}
