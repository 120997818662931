.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modalContainer {
    width: 500px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px;
}

.modalContainer .modal-title {
    margin-bottom: 10px;
}

.titleCloseBtn {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
}

.titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
}

.modalContainer .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
}

.modalContainer .body select {
    width: 100%;
    border-radius: 5px;
    outline: none;
    font-size: 14px;
    padding: 10px;
    border-color: #efefef;
}

.modalContainer .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 20px;
    margin-top: 20px;
}

.modalContainer .footer .success-btn {
    border-radius: 5px;
    border: none;
    outline: none;
    /*padding: 10px 40px;*/
    font-size: 14px;
    background-color: #0099ff;
    color: #fff;
    width: 100%;
}

.modalContainer .footer .ok-btn {
    /*padding: 10px 50px;*/
    background-color: #09f;
    color: #fff;
    width: 100%;
}

/*.modalContainer .footer .success-btn:hover {*/
/*  background-color: #0099ff;*/
/*  color: #fff;*/
/*}*/

.modalContainer .footer button {
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 14px;
    width: 100%;
}

.modalContainer .footer button:hover {
    background-color: #0099ff;
    color: #fff;
}

.modalContainer .footer .cancel-btn:hover {
    background-color: #efefef;
    color: #000;
}

#proposal .btn-secondary {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    outline: none;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff !important;
    border-radius: 5px;
    /*border: 1px solid #000;*/
    color: #000;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
}

#proposal .dropdown-menu {
    width: 100% !important;
    max-height: 200px;
    overflow: auto;
}

.selected-idea {
    font-size: 16px;
}

.Toastify {
    position: absolute;
}

input {
    width: 100%;
    outline: none;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

#category textarea {
    width: 100%;
}

#category .category-name {
    margin-bottom: 20px;
}

#category .category-status {
    display: flex;
    justify-content: space-between;
}

#category input[type="radio"] {
    width: unset;
    margin-right: 10px;
}

.changePassword-container {
    display: flex;
    flex-direction: column;
}

.changePassword-container input {
    padding: 10px;
    outline: none;
}

.modal-title {
    display: flex;
    justify-content: space-between;
}

.modal-title span {
    font-size: 18px;
    font-weight: 600;
    color: #0099ff;
}

.investor-form-parameter input[type="file"] {
    display: none;
}

.e-file-name{
	display: inline-block;
}

.e-file-size{
	display: block;
}