.layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    overflow: auto;
    position: relative;
}

/* navigation */
.nav-menu-wrapper {
    position: sticky;
    top: 0;
    background-color: #eceff5;
    padding: 20px 30px 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo-img {
    border-radius: 50% !important;
    margin-right: 10px;
}

.logo-txt {
    font-size: 22px;
    font-weight: 600;
    color: #09f;
}

@media (max-width: 1000px){
	.logo-txt{
		display: none;
	}
}

.nav-menu-wrapper a:hover {
    text-decoration: none;
}

.signup-left a:hover {
    text-decoration: none;
}

.ham-menu {
    display: none;
}

/* .nav-menu-item { */
/*  display: inline-block;*/
/*  vertical-align: middle;*/
/*  transform: scale(1.25);*/
/*  margin: 0px 30px;*/
/*  text-align: center;*/
/*  width: 100px;*/
/*  height: 34px;*/
/*  color: #4b4b4b;*/
/*  border-radius: 100px;*/
/*  text-decoration: none;*/
/*  transition: 0.3s;*/
/*  background-image: -webkit-gradient(*/
/*          linear,*/
/*          left top,*/
/*          left bottom,*/
/*          color-stop(0%, #f4f4f4),*/
/*          color-stop(100%, #eceff5)*/
/*  );*/
/*  background-image: -moz-gradient(*/
/*          linear,*/
/*          left top,*/
/*          left bottom,*/
/*          color-stop(0%, #f4f4f4),*/
/*          color-stop(100%, #eceff5)*/
/*  );*/

/*  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25), 10px 10px 15px #eceff5,*/
/*  -10px 10px 15px #eceff5, -15px -15px 15px rgba(255, 255, 255, 0.4),*/
/*  15px -15px 15px rgba(255, 255, 255, 0.4), inset 0px 2px 0px #fff8f8;*/

/*  -webkit-transition: box-shadow 0.3s ease-in-out,*/
/*  background-image 0.3s ease-in-out, text-shadow 0.5s linear,*/
/*  color 0.5s linear;*/
/*  -moz-transition: box-shadow 0.3s ease-in-out,*/
/*  background-image 0.3s ease-in-out, text-shadow 0.5s linear,*/
/*  color 0.5s linear;*/
/*  line-height: 35px;*/
/*  font-weight: 400;*/
/*  font-size: 0.8rem;*/
/*}*/

/*.nav-menu-item:hover {*/
/*  text-decoration: none !important;*/
/*  color: #09f;*/
/*  text-shadow: 0px 0px 7px lighten(#6394c6, 20%);*/

/*  background-image: -webkit-gradient(*/
/*          linear,*/
/*          left top,*/
/*          left bottom,*/
/*          color-stop(0%, #eceff5),*/
/*          color-stop(100%, #f4f4f4)*/
/*  );*/
/*  background-image: -moz-gradient(*/
/*          linear,*/
/*          left top,*/
/*          left bottom,*/
/*          color-stop(0%, #eceff5),*/
/*          color-stop(100%, #f4f4f4)*/
/*  );*/

/*  box-shadow: 0px 0px 3px rgb(0 0 0 / 15%), 10px 10px 15px rgb(255 255 255 / 40%),*/
/*  -10px 10px 15px rgb(255 255 255 / 40%), -10px -10px 15px #eceff5, 10px -10px 15px #eceff5,*/
/*  inset 0px -3px 14px rgb(255 255 255 / 40%), inset -1px -2px 6px rgb(0 0 0 / 4%);*/
/*}*/

/*.nav-menu-item.active {*/
/*  color: #09f;*/
/*  text-shadow: 0px 0px 7px lighten(#6394c6, 20%);*/

/*  background-image: -webkit-gradient(*/
/*          linear,*/
/*          left top,*/
/*          left bottom,*/
/*          color-stop(0%, #eceff5),*/
/*          color-stop(100%, #f4f4f4)*/
/*  );*/
/*  background-image: -moz-gradient(*/
/*          linear,*/
/*          left top,*/
/*          left bottom,*/
/*          color-stop(0%, #eceff5),*/
/*          color-stop(100%, #f4f4f4)*/
/*  );*/

/*  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15),*/
/*  10px 10px 15px rgba(255, 255, 255, 0.4),*/
/*  -10px 10px 15px rgba(255, 255, 255, 0.4), -10px -10px 15px #eceff5,*/
/*  10px -10px 15px #eceff5, inset 0px -3px 0px rgba(255, 255, 255, 0.4),*/
/*  inset 0px 3px 3px rgba(0, 0, 0, 0.04);*/
/*} */
.nav-menu-links {
    display: flex;
    column-gap: 20px;
}

.nav-menu-item {
    /*transform: scale(1.25);*/
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    font-size: 16px !important;
    text-align: center;
    width: 125px;
    padding: 10px;
    border-radius: 25px;
    text-decoration: none;
    transition: 0.3s;
    background-color: #fff;
    box-shadow: 0 5px 5px rgb(0 0 0 / 15%);
}

.nav-menu-item:hover {
    box-shadow: 0 2px 2px rgb(0 0 0 / 15%);
    transition: box-shadow 0.3s ease-in-out;
}

.nav-menu-item.active {
    color: #fff;
    background-color: #09f;
    box-shadow: 0 1px 1px rgb(0 0 0 / 15%);
    transition: box-shadow 0.3s ease-in-out;
}

.banner-links-container {
    display: flex;
    flex-direction: row;
}

.nav-menu {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.nav-more-menu {
    cursor: pointer;
    position: relative;
}

.nav-more-menu .viewMenu-dropdown li {
    list-style: none;
    padding: 10px 20px;
}

.nav-more-menu .viewMenu-dropdown li:hover {
    background-color: #ebedf4;
    border-radius: 10px;
}

/* by p */
.viewMenu-dropdown {
    position: absolute;
    top: 44px;
    right: -10px;
    padding: 0px;
    background-color: #fff;
    box-shadow: 0 5px 5px rgb(0 0 0 / 15%);
    border-radius: 5px;
    z-index: 999;
}

.nav-more-menu .dropdown-icon {
    font-size: 25px;
}

.mav-menu-login button {
    font-size: 16px;
    margin-right: 10px;
}

.nav-signup {
    padding: 5px 15px;
    border-radius: 5px;
    margin-right: 10px;
    border: 2px solid #717275;
    background-color: #eceff5;
}

.nav-signup:hover {
    background-color: #09f;
    color: #fff;
    border: none;
    border: 2px solid #09f;
}

.nav-login {
    border: none;
}

.nav-login:hover {
    color: #fff;
    background-color: #09f;
    transform: 0.3s;
    transition: 0.3s;
}

.navProfile {
    position: relative;
}

.profileMenu {
    position: absolute;
    top: 50px;
    right: 0px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 3px 5px rgb(0 0 0 / 15%);
    overflow: hidden;
    min-width: 160px;
}

.profileMenu button {
    font-size: 14px;
    background-color: #fff;
    display: flex;
    align-items: center;
    border: none;
    padding: 10px;
    width: 100%;
}

.profileMenu button:hover {
    background-color: #f3f3f3;
}

/* end navigation */

/* footer */
.footer-wrapper,
.footer-wrapper-mobile {
    background-color: #eceff5;
    text-align: center;
    font-size: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.footer-wrapper-mobile {
    margin-top: 15px;
    margin-bottom: 0px;
    background-color: #ffff;
    display: flex;
    justify-content: center;
}

/* end of footer */

/*mobile navbar css*/
.mobile-nav-wrapper {
    background-color: #fff;
    padding: 10px 0;
    box-shadow: 5px 0 5px rgb(0 0 0 / 15%);
    position: sticky;
    bottom: 0;
}

.mobile-nav-links {
    display: flex;
    justify-content: space-around;
}

.mobile-nav-link {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.mobile-nav-active {
    background-color: #09f;
    color: #fff;
    border-radius: 25px;
}

.mobile-nav-active a,
.mobile-nav-active:hover {
    color: #fff !important;
}

.mobile-nav-link span {
    display: none;
}

.mobile-nav-active span {
    display: block;
    font-size: 0.9rem;
    transition: ease-in-out;
}

.mobile-nav-wrapper {
    display: none;
}

/*.mobile-nav-active span{*/
/*  display: block;*/
/*  font-size: 12px;*/
/*}*/
/*mobile navbar css ends*/

@media screen and (max-width: 768px) {
    .ham-menu {
        display: block;
        color: #0099ff;
        /* position: absolute;
            top: 30px;
            right: 25px; */
    }

    .nav-menu-wrapper {
        padding: 10px;
    }

    .nav-menu-links {
        display: none;
    }

    .nav-dropdown {
        display: block;
        display: flex;
        justify-content: center;
    }

    .nav-dropdown .nav-dropdown-items {
        list-style: none;
        display: flex;
        flex-direction: column;
        /*row-gap: 20px;*/
        color: #000;
        padding: 0;
        width: 100%;
        margin-bottom: unset;
        border-radius: 5px;
    }

    .nav-dropdown .nav-dropdown-items a {
        color: #000;
    }

    .nav-dropdown .nav-dropdown-items li {
        padding: 10px 0;
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px solid #ccc;
    }

    .logo-container img {
        width: 25px;
        height: 25px;
    }

    .mobile-nav-wrapper {
        display: block;
        z-index: 99;
    }
}

@media only screen and (min-width: 1000px) {
    .nav-menu-wrapper {
        position: sticky;
        top: 0;
        z-index: 999;
    }
}

/* dropdown login*/
@media (max-width: 811px) {
	.inline-login {
		display: none;
	}
}

@media (min-width: 812px) {
	.dropdown-login {
		display: none;
	}
}

.drop-span{
	margin-left: 8px;
}