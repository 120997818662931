.menu-bars {
    margin-left: 1rem;
    font-size: 1.5rem;
    background: none;
    color: #a0a4a6;
}

.logo-db {
    margin-left: 15px;
}

.sidebar-menu.active {
    left: 0;
    transition: 450ms;
}

.sidebar-toggle {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.sidebar {
    width: 255px;
    min-height: 100%;
}

.mini-sidebar {
    width: 50px;
    min-height: 100%;
}

.sidebar-header {
    padding: 0 10px;
}

.sidebar-menu {
    color: #a9b7d0;
    width: 255px;
    height: 100%;
    display: flex;
    /* flex-direction: column;
    gap: 10px; */
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    box-shadow: 1px 3px 15px -15px #3f4d67;
    display: flex;
    flex-direction: row;
    background: transparent;
}

.sidebar-menu .menu-1 {
    padding: 10px 0;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.sidebar-menu .menu-2 {
    width: calc(100% - 255px);
    background-color: #000;
    opacity: 0.4;
    display: none;
}

.mini-sidebar-menu {
    background-color: #fff;
    color: #a9b7d0;
    width: 60px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-content: center;
    /* box-shadow: 1px 0 10px 0 #3f4d67; */
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    box-shadow: 1px 3px 15px -15px #3f4d67;
    padding: 10px 0;
}

.sidebar-menu-items {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    padding: 0 10px;
    margin: unset;
}

.mini-sidebar-menu-items {
    width: 100%;
    padding: 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.sidebar-logo {
    display: flex;
    position: relative;
    justify-content: center;
}

.sidebar-item {
    margin-top: 5px;
    list-style-type: none;
    height: 70vh;
    overflow: hidden;
    padding-bottom: 20px;
}

.sidebar-item:hover {
    overflow-y: auto;
}

.sidebar-item a {
    text-decoration: none;
}

.sidebar-link {
    padding: 10px 25px;
    width: 100%;
}

.mini-sidebar-link {
    padding: 10px 14px;
}

.sidebar-item section {
    transition: 0.2s;
}

.sidebar-link:hover {
    color: #434444;
    text-decoration: none;
    background-color: #eceff5;
    border-radius: 10px;
}

.sidebar-text:hover {
    text-decoration: none;
}

.sidebar-active > section {
    color: #434444;
    text-decoration: none;
    background-color: #eceff5;
    transition: 0.3s;
    border-radius: 10px;
}

.mini-sidebar-link:hover {
    color: #4344449a;
    text-decoration: none;
    background-color: #eceff5;
    border-radius: 10px;
}

.sidebar-item-section {
    margin: 4px 0;
}

.sidebar-home {
    margin: 0 10px;
}

.mainpage {
    display: flex;
    min-height: 100vh;
    background-color: #eceff5;
}

.sidebar-content {
    flex: 1;
    min-height: 100%;
    position: relative;
    z-index: 2;
}

.mini-sidebar-content {
    position: relative;
    min-height: 100%;
    width: 60px;
}

.right-content {
    /* flex: 11; */
    width: 100vw;
}

.right-content .dashboard-content {
    height: 80%;
}

.hide-ham {
    display: none;
}

.sidebar-icon,
.sidebar-text {
    color: #586a7c;
}

.sidebar-text {
    position: relative;
    top: 2px;
    margin-left: 10px;
    text-decoration: none;
}

.menu-bars-mini {
    color: #586a7c;
}

/* end of sidebar  */

/* topbar */

.topbar {
    width: 100%;
    background-color: #fff;
    height: 50px;
    position: sticky;
    top: 0;
    z-index: 999;
    box-shadow: 1px 3px 15px -15px #3f4d67;
    margin-bottom: 15px;
}

.topbar-wrapper {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-right {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.navicon-container {
    position: relative;
    cursor: pointer;
    color: #3f4d67;
    font-size: 20px;
    border: none;
    background-color: unset;
}

/*.searchBar {*/
/*  border: 1px solid #eee;*/
/*  border-radius: 10px;*/
/*}*/

/*.searchIcon {*/
/*  position: relative;*/
/*  left: -50px;*/
/*}*/

.topIconBadge {
    position: absolute;
    top: 2px;
    right: 1px;
    font-size: 9px;
    font-weight: 200;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #e74c3c;
    border: 1px solid #fff;
}

.topAvatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    object-fit: cover;
}

.nav-left {
    display: flex;
    align-items: center;
}

.searchBar {
    margin-left: 10px;
    padding-left: 20px;
    outline: none;
}

/* end of topbar  */

/* dropdown */

.wrapper {
    background-color: #fff;
    position: absolute;
    transition: 0.3s;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    width: 330px;
    right: 0;
    margin-top: 20px;
    /* max-height: 400px; */
}

.scroller {
    overflow: hidden;
    max-height: 400px;
}

.scroller:hover {
    overflow-y: auto;
}

.dropdown-head {
    border-bottom: 1px solid #dee0e5;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 15px 20px;
}

.dropdown-list {
    list-style: none;
    padding: 0px;
}

.dropdown-item {
    padding: 11px 20px !important;
    border-bottom: 1px solid #dee0e5;
    display: flex !important;
    flex-direction: row;
    align-items: center;
}

.dropdown-item:hover {
    background-color: #fcfcfc;
}

.dropdown-footer {
    display: flex;
    justify-content: center;
    /*border-top: 1px solid #dee0e5;*/
    font-size: 14px;
}

.dropdown-footer a:hover {
    text-decoration: none;
}

.profile-img {
    border-radius: 50px;
    object-fit: cover;
}

.dropdown-content {
    margin-left: 10px;
}

.notification {
    font-size: 14px;
    margin-bottom: 0px;
}

.time-stamp {
    font-size: 12px;
    margin: 0;
}

.mailWrapper {
    position: absolute;
    left: -250px;
}

.profileWrapper {
    position: absolute;
    right: 35px;
    background-color: #fff;
    position: absolute;
    transition: 0.3s;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-top: 20px;
}

.profile-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;

    align-items: center;
    margin: 0;
}

.profile-item {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
    cursor: pointer;
}

.profile-item:hover {
    background-color: #dee0e5;
    border-radius: 10px;
}

.sender {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
}

.mail {
    font-size: 14px;
}

.mail-container {
    width: 235px;
}

/* end of dropdown */

/* scrollbar */
.sidebar-item::-webkit-scrollbar {
    width: 5px; /* for vertical scrollbars */
}

.sidebar-item::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
}

.sidebar-item::-webkit-scrollbar-thumb {
    background: #212938;
    border-radius: 50px;
}

.sidebar-item::-webkit-scrollbar-thumb:hover {
    background: #a7a7a7;
}

/* end of scroll */

/* media queries */

@media all and (max-width: 796px) {
    .menu-bars-mini {
        display: block;
    }

    .sidebar {
        width: unset !important;
        z-index: 99999;
    }

    .mini-sidebar {
        z-index: 99999;
    }

    /*.searchBar {*/
    /*  margin-left: 20px;*/
    /*}*/
}

@media all and (max-width: 426px) {
    .mini-sidebar-content {
        display: none;
    }

    /*.searchBarContainer {*/
    /*  display: none;*/
    /*}*/
}
.sidebar-dropdown{
    .MuiButton-root{
        font-size: 12px;
        color: #000;
    }
        
}
a{
    font-size: 14px;
    color: #000;
}

.link1{
	color: #6394c6;
}

@media (min-width: 796px) {
	.nav-mini{
	  display: none;
	}
}
