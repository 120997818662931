.loader-wrapper {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    z-index: 99999999;
    background: rgba(0, 0, 0, 0.1);
    bottom: 0;
    text-align: center;
    padding-top: 15%;
}