// feed css
@media only screen and (max-width: 769px) {
  .cross_small_sidebar {
    padding: 6px 0px 0px 10px;

    svg {
      display: unset !important;
    }
  }
  .main-wrapper {
    min-height: unset !important;
  }
  .carousel .slide img {
    height: 394px !important;
    object-fit: contain !important;
  }
  .span_front {
    display: block;
    width: 100%;
    padding: 20px 40px;
  }
  .sidebar-logo {
    img {
      height: 30px;
      width: 30px;
      margin-left: 0px;
    }

    b {
      font-size: 16px !important;
    }
  }
  .res_table {
    display: none;
  }
  .res_show_table {
    display: table-row;
  }
  .investor-form {
    overflow-x: auto;
  }
  .profile_detail_main {
    margin: 0;
    padding: 0;
  }
  .td_investor_title {
    width: 200px !important;
  }
  .menu-2 {
    display: block !important;
  }
  .sidebar-menu {
    width: 100% !important;
  }
  .sidebar-menu .menu-1 {
    width: 255px !important;
  }
  .mobile_nav__menu {
    display: unset !important;
  }
  .ham-menu {
    display: flex !important;
    justify-content: center;
    align-items: center !important;
  }
  .nav__mini {
    display: unset !important;
  }
  .nav-left {
    display: none !important;
  }
}

//
.like-counter {
  svg {
    font-size: 23px;
  }

  span {
    font-size: 14px;
    margin-top: 4px;
  }
}

.favourite-btn {
  svg {
    font-size: 22px;
  }
}

.views-counter {
  svg {
    font-size: 20px;
  }

  span {
    font-size: 14px;
  }
}

.nav-dropdown-items {
  padding: 0 15px !important;
}

.feed__location svg {
  color: #a3a3a3;
  font-size: 12px;
}

.h4_pd {
  font-size: 1.2rem;
  color: #09f;
  margin: 0;
}

.mobile_nav__menu {
  display: none;
}

.nav__mini {
  display: none;
}

@media only screen and (max-width: 426px) {
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 0.8rem !important;
  }
  .h4_pd {
    font-size: 1.2rem;
    color: #09f;
    margin-bottom: 15px !important;
  }
  .form_wrap_mobile {
    margin: 0 !important;
  }

  .recommendation-column {
    display: none;
  }
  .idea-detail {
    row-gap: 8px !important;
  }
  #idea-page .box-3 {
    display: none;
  }
  .idea-form {
    .MuiGrid-root {
      margin: 7px 0px;
      padding: 0;
      padding-left: 0px !important;
      padding-top: 0px !important;
    }

    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 12px 12px;
    }
  }
  .nav-dropdown-items {
    padding: 0 15px;
  }
  .layout {
    padding-bottom: 50px;
    height: 100vh;
  }
  #idea-page,
  #investor-page {
    // height: calc(100vh - 132px);
    min-height: unset;
  }
  .other-logins {
    flex-direction: row !important;
  }
  .top-banner-txt-container {
    align-items: unset !important;
  }
  .span_front {
    padding: 35px 20px;
  }
  .signup_top_msg_box {
    margin-bottom: -8px !important;
    margin-top: 5px;

    svg {
      height: 31px !important;
    }

    .signup_msg {
      font-size: 0.85rem !important;
    }

    .success-msg {
      padding: 6px !important;
    }
  }
  .banner-link-item {
    padding: 13px 15px !important;
  }
  .banner-links {
    grid-row-gap: 15px;
    grid-column-gap: 12px;
    row-gap: 11px;
    display: grid !important;

    grid-template-columns: 1fr 1fr;
  }
  .carousel .slide img {
    height: 295px !important;
    object-fit: contain;
  }
  .footer-wrapper {
    // position: fixed !important;
    // top: 90% !important;
    // width: 67% !important;
    // left: 13% !important;
    // background-color: unset !important;
    // font-size: 9px !important;
  }
  .nav-dropdown {
    margin: 0 !important;
  }

  .signup-header {
    margin-bottom: 5px !important;
  }
  .mobile-nav-wrapper {
    position: fixed !important;
    width: 100% !important;
    padding: 10px 0px !important;
  }
  .banner-link-text {
    padding-right: 0;
  }
  .MuiInputLabel-outlined {
    z-index: 1 !important;
  }
  .page-item.active .page-link {
    z-index: 0 !important;
  }
  .span_front1 {
    font-size: 1.2rem !important;
    font-weight: 500 !important;
  }
  .top-title {
    h4 {
      font-size: 1.2rem;
      font-weight: 400;
      margin-bottom: 1.1rem;
    }
  }
  .signup-wrapper {
    height: calc(100vh - 92px);
    min-height: unset !important;
  }
  .idea-feed-block {
    padding: 0px 0px 0px 9px !important;
    margin-top: 10px;
    row-gap: 10px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .feed-body {
    padding: 0 10px 0 10px !important;
  }
  .idea-detail .detail-item {
    padding-bottom: 8px !important;
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    border: none !important;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .dash_h3 {
    font-size: 1.1rem;
  }
  .idea-detail .feed-counter {
    border: none !important;
    // padding: 0 !important;
  }
  .feed-counter {
    padding: 5px 2px 2px 0 !important;
  }
  .feed-btn {
    column-gap: 120px !important;
    width: 100% !important;
  }
  .feed-footer-btn {
    padding: 8px 0px 10px !important;
    row-gap: 15px !important;
  }
  .follow_investor {
    padding: 8px 30px;
    width: 100% !important;
  }
  .favourite-btn svg {
    font-size: 23px !important;
  }
  .detail-container .idea-detail-head .feed__location {
    padding: 0px 0px;
    padding-top: 5px;
  }
  .investor_follow_wrapper {
    position: unset !important;
  }
  .follow_investor {
    padding: 10px 30px;
  }
  .detail-container .idea-detail-head {
    padding: 20px 15px 0px 15px !important;
  }

  .give-rating button {
    padding: 10px 22px 10px 22px !important;
  }
  .detail-container .detail-item {
    padding: 5px 15px 10px 15px !important;
  }

  .detail-container .idea-detail-img img {
    height: 248px !important;
  }
  .detail-container .details-blocks {
    padding: 10px 15px;
  }
  .detail-container .idea-detail-hidden-box {
    padding: 10px 15px 10px 12px !important;
  }
  .rate-box {
    right: 30px !important;
    top: 103px !important;
    padding: 6px 17px !important;
  }
  .idea-parameter .idea-form-parameter input {
    width: 65px !important;
  }
  .feed-head {
    padding: 20px 20px 0px 20px !important;
  }
  .idea-detail_top {
    flex-direction: column;
  }

  #idea-form .btn-secondary {
    width: 154px !important;
  }
  .idea-location-parameter {
    flex-direction: column;
  }
  .form-wrapper {
    // z-index: -1 !important;
    position: relative;
  }
  .idea-parameter {
    flex-direction: column;
  }
  #investor-type-drop button {
    width: 95% !important;
  }
  .screening_wrap .personal_detail_wrap .body_flex {
    flex-direction: column;
  }
  .screening_wrap .personal_detail_wrap .body_flex .detail_table {
    justify-content: center;
  }
  .screening_wrap .personal_detail_wrap .body_flex .user_image img {
    height: 155px;
  }
  .screening_wrap .personal_detail_wrap .body_flex .user_image {
    margin-bottom: 0.8rem;
  }
  .screening_wrap .end_buttons {
    flex-direction: column;
    gap: 10px;
  }
  .screening_wrap .end_buttons .button1 {
    justify-content: center;
  }
  .screening_wrap .end_buttons .button2 {
    display: flex;
    justify-content: center;
  }
  .screening_wrap .personal_detail_wrap .body_flex .detail_table tr td {
    font-size: 0.9rem;
  }
  .screening_wrap .my_investment {
    display: unset;
  }
  .screening_wrap .end_buttons button {
    width: 100%;
    height: 45px !important;
    font-size: 1rem;
  }
  .feed-image-container img {
    height: 260px !important;
    width: 100%;
    object-fit: cover;
  }
  .main-wrapper {
    min-height: unset !important;
  }
  .investor-form {
    overflow-x: unset !important;
  }
  .feed {
    padding-bottom: 15px;
  }
  .table-contents {
    height: unset !important;
  }
  .modalContainer {
    margin: 10px;
  }
}

@media only screen and (max-width: 376px) {
  .detail-container .details-text {
    font-size: 0.8rem;
  }
  .signup-wrapper {
    height: calc(100vh - 23px) !important;
  }

  .add-profile {
    padding: 0 !important;
  }
  .feed-btn {
    column-gap: 103px !important;
  }
  .other-logins {
    flex-direction: column !important;
  }
  .nav-dropdown-items {
    padding: 0 15px !important;
  }
  .noAcc {
    justify-content: center !important;
    margin-top: 5px !important;
  }
  .banner-links {
    display: flex !important;
    flex-direction: column !important;
  }
  .footer-wrapper {
    margin-bottom: 0 !important;
  }
}

.dash_h3 {
  margin: 15px 0px;
  font-size: 1.1rem;
  font-weight: 500;
}

.noAcc1 {
  margin-top: 10px;
  text-align: center;
  color: #2e2e2e;
  font-size: 0.9rem;
}

@media only screen and (max-width: 320px) {
  .e-upload .e-file-select-wrap .e-file-drop {
    font-size: 11px !important;
  }
  .feed-btn {
    column-gap: 74px !important;
  }
  .css-1tm81ay {
    width: unset !important;
  }
  .top-title h4 {
    font-size: 1.1rem;
  }
  .dash_h3 {
    font-size: 1rem;
    font-weight: 500;
  }
  .f_wrap {
    width: 84% !important;

    .css-wb57ya-MuiFormControl-root-MuiTextField-root {
      width: 84% !important;
    }
  }
  .d_text {
    margin-top: 2px;
    font-size: 13px;
  }
  .form_wrap_mobile {
    width: 88% !important;
  }
}
