.profile-detail {
    background-color: #fff;
    border-radius: 5px;
    padding: calc(10px + 1vw);
    box-shadow: 0 1px 5px rgb(0 0 0 / 16%);
    margin-bottom: 20px;
    position: sticky;
    top: 85px;
}

.profile-head {
    position: relative;
    display: flex;
    justify-content: center;
}

.profile-img {
    border-radius: 50%;
}

.profile-userDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.profile-name {
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
}

.profile-subscription {
    font-size: 12px;
    color: #555;
}

.add-profile {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-direction: column;
}

.add-profile-btn {
    background-color: #09f;
    border-radius: 5px;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    cursor: pointer;
}

.profile-menu {
    background-color: #09f;
    color: #fff;
    border-radius: 5px;
    margin-top: 5px;
}

.profile-menu-item {
    padding: 10px;
    border-bottom: 1px solid #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.profile-menu-item:hover {
    background-color: #4e88c2;
    border-radius: 5px;
}

.profile-menu-item:active {
    background-color: #6394c6;
    border-radius: 5px;
}

.profile-menu a {
    text-decoration: none;
    color: #fff;
}

.profile-stats {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.stats-count {
    text-align: center;
    font-weight: 600;
    font-size: 14px;
}

.stats-label {
    font-size: 12px;
    font-weight: 600;
    color: #3f3f3f;
}

.profile_setting {
    cursor: pointer;
    position: absolute;
    right: 0;
}

.profile-setting-open {
    position: absolute;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 5px 5px rgb(0 0 0 / 15%);
    top: 25px;
    right: -10px;
}

.profile-setting-open button {
    outline: none;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
}

.profile-setting-open button:hover {
    background-color: #fdfdfd;
}

.profile-guest {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 5px rgb(0 0 0 / 15%);
    margin-bottom: 20px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 75px;
}

.profile-guest .profile-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile-guest .profile-body .profile-body-upper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #eceff5;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.signup-btn {
    background-color: #09f;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    border: none;
}

#profile .btn-secondary {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    outline: none;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #09f !important;
    border-radius: 4px;
    /*border: 1px solid #000;*/
    color: #fff;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
}

#profile .dropdown-menu {
    width: 100%;
}

@media screen and (max-width: 768px) {
}
