.detail-container {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 5px 5px rgb(0 0 0 / 15%);
    margin-bottom: 20px;
    min-height: calc(100% - 20px);
}

.parameter-heading {
    font-weight: 600;
}

.form-container {
    background-color: #09f;
    border-radius: 5px;
    box-shadow: 0 5px 5px rgb(0 0 0 / 15%);
    padding: 20px 10px;
}

.idea-detail-head {
    padding: 20px;
    position: relative;
}

.idea-detail-head .idea-title {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
}

.idea-detail-head .idea-contacts {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.idea-detail-body {
    padding: 20px;
}

.details-blocks {
    display: flex;
    justify-content: space-between;
}

.details-blocks .detail-item {
    background-color: #eceff5;
}

.idea-detail .detail-item {
    /*padding: 20px;*/
    border-radius: 0px 5px 5px 0px !important;
    margin-bottom: unset;
    background-color: #eceff5;
}

.detail-item {
    /*padding: 20px;*/
    justify-content: flex-end;
    border-radius: 10px;
    margin-bottom: 40px;
    margin-bottom: 40px;
    position: relative;
}

.hidden-item::before {
    content: "Available after Connect";
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 10px;
    background-color: #efefef;
    transition: transform 200ms ease-in-out;
    transform: scaleX(0);
    transform-origin: left;
}

.hidden-item:hover::before {
    transform: scaleX(1);
}

.details-text {
    text-align: justify;
    color: #252424;
    font-weight: 400;
}

.detail-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.idea-detail-hidden-box .detail-title {
    border-bottom: 3px solid #eceff5;
}

.detail-item .detail-title {
    font-size: 16px;
    font-weight: 400;
}

.form-container {
    margin-top: 50px;
}

.idea-contacts span {
    display: flex;
    align-items: center;
}

.idea-contacts-icon {
    font-size: 20px;
    margin-right: 10px;
}

.rate-box {
    z-index: 999;
    position: absolute;
    right: 50px;
    top: 60px;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
}

.rate-box button {
    border: none;
    width: 250px;
    padding: 10px;
    border-radius: 5px;
}

.rate-box button:hover {
    color: #fff;
    background-color: #0099ff;
}

.investor_follow_wrapper {
    font-size: 11px;
    right: 20px;
    top: 20px;
    cursor: pointer;
    display: flex;
}

.follow_investor.idea.inner {
    margin-right: 10px;
}

.give-rating button {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    font-size: 12px;
}

.give-rating button:hover {
    background-color: #edeef1;
}

.hidden {
    color: transparent;
    text-shadow: 0 0 7px rgba(0, 0, 0, 0.75);
}

.idea-detail-hidden-box {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.idea-detail-hidden-box .detail-item {
    border-right: 5px;
    border: 2px solid #eceff5;
    padding: 5px;
}

.location-block {
    max-width: 240px;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.location-pill label {
    display: flex;
    justify-content: center;
}

.idea-detail-footer {
    padding-bottom: 20px;
    margin: 0 20px;
    display: flex;
    justify-content: center;
}

.idea-detail-footer button {
    border: none;
    padding: 10px;
    border-radius: 5px;
}

.idea-detail-footer button:hover {
    color: #fff;
    background-color: #0099ff;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
}

.detail-action-response {
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    background-color: #eceff5;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 811px) {
    .details-blocks {
        flex-direction: column;
    }

    .location-block {
        max-width: unset;
    }

    .idea-detail {
        display: flex;
        flex-direction: column;
    }

    .idea-detail-hidden-box {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}
