.main-wrapper {
    /*height: calc(100vh - 80px);*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 85vh;
}

.primary-txt {
    font-size: calc(12px + 1.5vw);
    text-shadow: 4px 2px 3px rgb(0 0 0 / 15%);
    font-weight: 400;
}

.secondary-txt {
    font-size: calc(20px + 1.5vw);
    font-weight: 700;
    color: #09f;
}

.banner-links {
    width: 100%;
    list-style: none;
    padding: 10px;
    font-size: calc(10px + 1.5vw);
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.top-banner-right {
    display: none;
}

.top-banner-txt-container {
    align-items: center;
}

.hidden-banner {
    display: block;
}

.banner-links a {
    text-decoration: none;
    color: black;
}

.banner-link-item {
    color: #000;
    padding: 15px 20px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 25px;
    font-weight: 400;
}

.banner-link-item:hover {
    box-shadow: 0 5px 5px rgb(0 0 0 / 15%);
    transition: box-shadow 0.3s ease-in-out;
}

.banner-link-item:active {
    box-shadow: 0 1px 2px rgb(0 0 0 / 15%);
}

.top-banner-txt-container {
    display: flex;
    flex-direction: column;
}

/*!* responsive *!*/
@media screen and (min-width: 769px) {
    /*@media screen and (min-width: 500px) {*/
    .hidden-banner {
        display: none;
    }

    .top-banner-right {
        display: block;
    }

    .top-banner {
        background-color: #eceff5;
        display: flex;
        justify-content: space-between;
        /*height: calc(100vh - 110px);*/
    }

    .top-banner-left,
    .top-banner-right {
        display: flex;
        align-items: center;
        padding-left: 80px;
    }

    .primary-txt {
        font-size: calc(15px + 1.5vw);
    }

    .secondary-txt {
        font-size: calc(20px + 1.5vw);
    }

    .banner-links {
        margin-top: 20px;
        display: grid;
        gap: 20px;
        grid-template-columns: calc(210px + 1.5vw) calc(210px + 1.5vw);
    }

    .banner-link-item {
        font-size: 14px;
    }

    .top-banner-txt-container {
        align-items: flex-start;
    }
}
