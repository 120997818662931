.signup-wrapper {
    display: flex;
    justify-content: space-around;
    padding: 0 40px;
    margin-bottom: 10px;
    min-height: 80vh;
    height: calc(100vh - 120px);
}

.signup-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 300px;
    margin: 20px 0;
}

.signup-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.signup-right .lamp {
    /*position: absolute;*/
    /*top: 0;*/
    /*right: 50px;*/
    height: calc(200px + 1.5vw);
    width: auto;
}

.signup-right .man {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    height: auto;
    width: calc(200px + 1.5vw);
}

.signup-header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
}

.signup-header button {
    border: none;
    background-color: #fff;
}

.other-logins {
    display: flex;
    justify-content: center;
    column-gap: 10px;
    row-gap: 10px;
    margin-bottom: 10px;
}

.socials {
    display: flex;
    align-items: center;
    background-color: #f2f3f5;
    border-radius: 5px;
    padding: 8px 10px;
    cursor: pointer;
}

.socials:hover {
    background-color: #eceff5;
}

.socials-logo {
    font-size: 20px;
    margin-right: 10px;
    display: flex;
    align-self: center;
}

.socials-txt {
    color: #333;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
}

.login-divider {
    display: flex;
    justify-content: center;
    color: #abb6c3;
    font-size: 14px;
}

.signup-form-container {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px 20px;
    box-shadow: 0 5px 5px rgb(0 0 0 / 15%);
    /* min-height: \; min-height: 56; */
    width: 396px;
}

.signup-head {
    font-size: 18px;
    font-weight: 500;
    position: relative;
}

.signup-form form .user-details {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-bottom: 10px;
}

/*form .user-details .input-box {*/
/*  !*width: calc(100% / 2 - 20px);*!*/
/*  width: 100%;*/
/*  margin-bottom: 10px;*/
/*}*/

/*.user-details .input-box input {*/
/*  width: 100%;*/
/*  border-radius: 3px;*/
/*  outline: none;*/
/*  background-color: #fff;*/
/*  padding: 5px 20px 5px 10px;*/
/*  border: 1px solid #abb6c3;*/
/*}*/
.user-details .input-box input:focus {
    border-color: #344c6b;
}

.user-details .input-box .signup-title {
    display: block;
    /* font-weight: 500; */
    margin-bottom: 5px;
    font-size: 14px;
    color: #344c6b;
    margin-top: 10px;
}

form .button {
    display: flex;
    justify-content: center;
}

form .button input:hover {
    background-color: #2d86fc;
}

form .button input:active {
    background-color: #6394c6;
}

form .button button {
    height: 100%;
    outline: none;
    color: #fff;
    background-color: #09f;
    border: none;
    padding: 9px 33px;
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
}

@media (max-width: 584px) {
    .signup-wrapper {
        display: flex;
        flex-direction: column;
        padding: unset;
        margin: 0 10px;
        height: 100vh;
    }

    .signup-form-container {
        width: 100%;
        margin-bottom: 10px;
    }

    .signup-form {
        max-width: 100%;
    }

    form .user-details .input-box {
        width: 100%;
        margin-bottom: 10px;
    }

    .other-logins {
        flex-direction: column;
    }

    .signup-right {
        display: none;
    }

    /*.socials {*/
    /*  justify-content: center;*/
    /*}*/
}
