/* Database table */
.nav-tabs {
    color: #3f4d67;
    font-size: 14px;
    margin: 0 15px;
    border-bottom: none !important;
    /*margin-top: 15px;*/
}

.table-header {
    background: #fcfcfc;
    padding: 10px;
    color: #468fea;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table-header button:hover {
    color: #fff;
}

.table-header span {
    font-size: 22px;
    font-weight: 600;
    margin-left: 15px;
}

.table-header input {
    border-radius: 10px;
    border: none;
    padding: 5px 10px;
    margin-right: 10px;
}

.tab-content {
    padding: 0;
}

.table-contents table {
    margin: 0;
    width: 100%;

}

#db-table thead {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #468fea;
}

.table thead th {
    border: none;
}

#db-table tbody {
    font-size: 14px;
    font-weight: 400;
    color: rgba(31, 38, 52, 1);
}

#db-table tbody td {
    vertical-align: middle;
    text-align: left;
}

#db-table thead th {
    text-align: left;
    padding: 10px;
}

#db-table tbody tr td {
    padding: 10px;
    font-weight: 400;
}

/*table, th, td{*/
/*  border: 1px solid;*/
/*}*/

#db-table tbody tr:nth-child(odd) {
    background-color: #eff6ff;
}

#db-table tbody tr td button {
    background-color: #2196f3;
    padding: 10px 10px;
    border: none;
    color: #f8f0e3;
    border-radius: 5px;
    font-size: 12px;
    min-width: 95px;
}

#db-table,
#db-table td {
    border: 1px solid #e1e1e1;
    border-top: 0;
}

#db-table tbody tr td button:hover {
    background-color: #4ccabe;
    min-width: 95px;

    box-shadow: 0 5px 5px rgb(0 0 0 / 15%);
}

.table-header button {
    outline: none;
    border: none;
    padding: 10px;
    background: #2196f3;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    margin: 0;
    width: 100px;
}

.table-wrapper {
    /* height: calc(100vh - 215px); */
    width: 100%;
    /*margin: 15px auto;*/
    /*box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);*/
    background-color: #fff;
    /* min-height: 80vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.table__full_height {
    height: calc(100vh - 186px);
}

.search input {
    outline: none;
}

#uncontrolled-tab-example .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border-radius: 5px 5px 0 0;
    background-color: #468fea !important;
    color: #fff !important;
    border: none;
}

.nav-tabs .nav-link:hover {
    border-radius: 5px 5px 0 0;
    border-color: #e9ecef #e9ecef transparent #dee2e6 !important;
    transform: 0.5ms ease-in;
}

.nav-tabs .nav-link {
    color: #3f4d67;
}

.nav-tabs .nav-item {
    margin-right: 5px;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    padding: 5px 15px 0 0;
    align-items: center;
    /* background: #468fea; */
    /* color: #fff; */
    border-radius: 0 0 10px 10px;
}

.searchbar .hide {
    opacity: 0;
    width: 0;
    height: 0;
}

.table-filter {
    cursor: pointer;
}

.table-filter .filter {
    position: absolute;
    top: 45px;
    left: -40px;
    background-color: #fff;
    box-shadow: 0 5px 5px rgb(0 0 0 / 15%);
    border-radius: 10px;
    z-index: 999;
}

.table-filter .filter ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.table-filter .filter li {
    cursor: pointer;
    padding: 10px;
}

.table-filter .filter li:hover {
    background-color: #fcfcfc;
    border-radius: 10px;
}

.searchbar {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
}

.search{
	display: flex;
	position: relative;
}

.search input {
    outline: none;
    border: none;
    border-radius: 5px;
}

.filter-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 75px;
    right: 25px;
}

.table-title {
    margin-bottom: 10px;
}

.table-rejected-btn {
    border-radius: 5px;
    padding: 5px 8px;
    font-size: 12px;
    background: #f55555;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
}

.table-accepted-btn {
    border-radius: 5px;
    padding: 5px 8px;
    font-size: 12px;
    background: #63d75b;
    color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
}

.table-ongoing-btn {
    border-radius: 5px;
    padding: 5px 8px;
    font-size: 12px;
    background: #e5b23d;
    color: #FFF;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
}

.table-warning-btn {
    border-radius: 5px;
    padding: 5px 8px;
    font-size: 12px;
    /*background: #e5b23d;*/
    color: #f5c255;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
}

.table-btn {
    background-color: #09f;
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    display: flex;
    column-gap: 5px;
    align-items: center;
    border: none;
}

.no_data_found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #515151;
}

/* end of Database table  */
