.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: 99999999;
}

.modalContainer {
    width: 500px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
}

.titleCloseBtn {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
}

.titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
}

/*.modalContainer .body {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: center;*/
/*  font-size: 16px;*/
/*  !*margin: 20px 0;*!*/
/*}*/

.modalContainer .body select {
    width: 100%;
    border-radius: 5px;
    outline: none;
    font-size: 14px;
    padding: 10px;
    border-color: #efefef;
}

/*.modalContainer .footer {*/
/*  flex: 20%;*/
/*  display: flex;*/
/*  justify-content: space-around;*/
/*  align-items: center;*/
/*}*/

/*.modalContainer .footer button {*/
/*  border-radius: 5px;*/
/*  border: none;*/
/*  outline: none;*/
/*  padding: 10px;*/
/*  font-size: 14px;*/
/*  margin: 10px;*/
/*  width: 100%;*/
/*}*/

/*.modalContainer .footer button:hover {*/
/*  background-color: #0099ff;*/
/*  color: #fff;*/
/*}*/

/*#proposal .btn-secondary {*/
/*  width: 100%;*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  align-items: center;*/
/*  outline: none;*/
/*  padding: 10px;*/
/*  margin-bottom: 10px;*/
/*  background-color: #fff !important;*/
/*  border-radius: 4px;*/
/*  !*border: 1px solid #000;*!*/
/*  color: #000;*/
/*  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);*/
/*}*/

#proposal .dropdown-menu {
    width: 100%;
    height: 200px;
    overflow: auto;
}

.selected-idea {
    font-size: 16px;
}

.Toastify {
    position: absolute;
}

#category input {
    width: 100%;
    outline: none;
    padding: 10px;
}

#category textarea {
    width: 100%;
}

#category .category-name {
    margin-bottom: 20px;
}

#category .category-status {
    display: flex;
    justify-content: space-between;
}

#category input[type="radio"] {
    width: unset;
    margin-right: 10px;
}
  