/* .feed {
    height: calc(100vh - 80px);
    overflow: auto;
} */

/*.Profile {*/
/*  background-color: #fff;*/
/*  border-radius: 10px;*/
/*  padding: 25px 30px;*/
/*  box-shadow: 0 5px 5px rgb(0 0 0 / 15%);*/
/*  margin-bottom: 20px;*/
/*}*/

.add-idea {
    height: 150px;
    background-color: #fff;
    border-radius: 10px;
    padding: 25px 30px;
    box-shadow: 0 5px 5px rgb(0 0 0/15%);
    margin-bottom: 30px;
    width: 100%;
}

.add-idea button {
    border: none;
    outline: none;
    background-color: #09f;
    color: #fff;
    border-radius: 10px;
    padding: 10px;
}

[class^="box-"] {
    display: grid;
}

.idea-wrapper {
    height: 100%;
    width: 100%;
    margin: 0 auto;
}

#idea-page {
    margin: 0 10px;
    /*min-height: 85vh;*/
    /*height: 100vh;*/
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:
    "p p p p  p p p p  p p p p"
    "f f f f  f f f f  f f f f"
    "a a a a  a a a a  a a a a";
}

#idea-page .box-1 {
    grid-area: p;
    z-index: 99;
}

#idea-page .box-2 {
    grid-area: f;
}

#idea-page .box-3 {
    grid-area: a;
}

.feed {
    min-height: 85vh;
}

@media only screen and (min-width: 1024px) {
    #idea-page {
        display: grid;
        padding: 0 40px;
        margin: unset;
        gap: 20px;
        grid-template-columns: repeat(12, 1fr);
        grid-template-areas: "p p p f f f f f f a a a";
    }

    #idea-page .box-1 {
        grid-area: p;
    }

    #idea-page .box-2 {
        grid-area: f;
    }

    #idea-page .box-3 {
        grid-area: a;
    }
}
