.scheduler-form-item {
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;
    margin-right: 20px;
    padding: 10px;
}

.scheduler-form-input {
    width: 60%;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #e7d6d6;
    padding-left: 10px;
}

.scheduler-form-input:focus {
    border: 1px solid #e7d6d6;
    padding-left: 10px;
}

.scheduler-form-header {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #5eb8ff;
    color: #fff !important;
    border-radius: 10px;
    color: #202736;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    font-weight: 800;
    padding: 10px 0px;
    margin-bottom: 10px;
}



.form-header {
    text-align: center;
    margin-top: 10px;
}


