.investor-detail-container {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 5px 5px rgb(0 0 0 / 15%);
    margin-bottom: 20px;
    padding: 20px;
    position: relative;
}

.investor-detail-header {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
}

.investor-back-btn {
    color: #09f;
    padding: 6px 8px 6px 0px;
}

.investor-back-btn:hover {
    color: #09f;
}

.investor-back-btn:active {
    background-color: #e6e7e9;
}

.investor-detailed-info {
    margin: 0px 20px;
    font-size: 14px;
}

.investor-info-parameter .category-list label,
.investor-info-parameter .location-list label {
    font-weight: 500;
    margin: unset;
}

.investor-info-parameter .category-lists span,
.investor-info-parameter .location-lists span {
    padding: 5px;
    border-radius: 5px;
    background-color: #67e081;
    margin: 5px 5px;
    color: #fff;
}

.investor-detail-header .profile-img {
    position: absolute;
    top: 30px;
    border: 5px solid #fff;
}

.investor-detail-body {
    display: flex;
    flex-direction: row;
}

.investor-info-parameter .parameter-value {
    text-align: justify;
}

.proposal-btn-container label {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #fff;
}

.proposal-btn-container button {
    outline: none;
    border: none;
    padding: 5px;
    border-radius: 5px;
    background-color: #eceff5;
}

.proposal-btn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.proposal-btn {
    display: flex;
    justify-content: space-around;
}

.proposal-form label {
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
}

.proposal-form-parameters {
    padding: 0 10px;
}

.proposal-form-parameters li {
    list-style: none;
    margin-bottom: 10px;
}

.proposal-form-parameters li button {
    outline: none;
    border: none;
    padding: 5px;
    border-radius: 5px;
    background-color: #eceff5;
    width: 100%;
}

.proposal-form-parameters .form-heading {
    color: #fff;
}

.proposal-form-parameters .form-value input {
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
}

.proposal-form-parameters .form-value textarea {
    outline: none;
}

.investment-label {
    background-color: #5cd08d;
    border-radius: 20px;
    padding: 5px 10px;
    color: #fff;
    font-size: 12px;
}

.investment-label {
    display: flex;
    justify-content: center;
}

.location-pills {
    display: flex;
    flex-wrap: wrap;
}

.location-pills label {
    border-radius: 5px;
    background-color: #eceff5;
    padding: 5px 10px;
    font-size: 12px;
    padding: 5px 10px;
    margin: 5px;
}

.investor-info-parameter li {
    list-style: none;
    padding: 5px 0;
}

.investor-info-parameter {
    padding: 0;
}

.investor-detail-container .investor-detail-footer {
    padding: unset;
    flex-direction: row;
    display: flex;
    justify-content: center;
}

.investor-detail-top {
    border-radius: 10px 0;
    font-size: 14px;
    margin-bottom: 20px;
    position: relative;
}

.investor-detail-row {
    border-radius: 10px;
    font-size: 14px;
    margin-bottom: 10px;
}

.investor-personal-detail {
    display: flex;
    border-radius: 10px;
    border: 2px solid #eceff5;
    padding: 10px 0px 20px 0px;
}

.investor-personal-detail .investor-detail-left {
    padding: 0 10px;
}

.investor-personal-detail .investor-detail-right {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.investor-detail-blockrow {
    display: grid;
    grid-template-columns: 30% 40% 27%;
    grid-gap: 10px;
    margin-bottom: 15px;
}

.personal-detail-item label {
    font-weight: 500;
    margin: unset;
}

.investor-detail-header .socials-verify {
    width: 150px;
}

.investor-detail-row .category-list,
.investor-detail-row .location-list {
    border: 2px solid #eceff5;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    padding: 5px;
    /*flex-wrap: wrap;*/
}

.category-lists,
.location-lists {
    /*display: grid;*/
    /*gap: 5px;*/
    /*grid-template-columns: 100px 100px 100px;*/
    display: flex;
    row-gap: 5px;
    column-gap: 5px;
    flex-wrap: wrap;
    margin-left: 5px;
}

.investor-detail-row .category-list label,
.investor-detail-row .location-list label {
    font-size: 1rem;
    font-weight: 500;
    margin: unset;
}

.investor-detail-row .category-lists span,
.investor-detail-row .location-lists span {
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    background-color: #eceff5;
    display: flex;
    font-weight: 400;
    justify-content: center;
    margin: 3px 0;
}

.investor-detail-row .investor-description,
.investor-detail-row .investor-interests {
    font-size: 14px;

    height: unset;
    padding: unset;
}

.investment-row {
    background-color: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: unset;
}

.download-file a {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #eceff5;
    border-radius: 10px;
    padding: 10px 10px;
    font-size: 12px;
}

.download-file .d_f {
    padding-right: 10px;
    color: #2691ff !important;
    font-weight: 400;
}

.d_f_svg {
    color: rgb(160, 13, 13);
}

.hidden-detail {
    width: 100%;
    border: 2px solid #eceff5;
    z-index: 999;
    top: 0;
    left: 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: baseline;
    padding: 10px;
}

/*investor-detail-footer button {*/
/*  border: none;*/
/*  padding: 10px;*/
/*  border-radius: 5px;*/
/*  background-color: #0099ff;*/
/*  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);*/
/*  color: #fff;*/
/*}*/

.investor-detail-footer button:hover {
    background-color: #a3a3a3;
}

.proposal-btn {
    border: none;
    padding: 10px;
    border-radius: 5px;
    background-color: #0099ff;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    color: #fff;
}

.investor-detail-header .give-rating button {
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    background-color: #fff;
    padding: 10px;
}

.investor-personal-infos {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 20px;
    row-gap: 5px;
}

.investor-detail-header-right,
.investor-detail-header-left {
    display: flex;
    column-gap: 10px;
}

.investor-detail-header-mid {
    display: flex;
    column-gap: 20px;
}

.like-btn,
.favourite-btn {
    cursor: pointer;
    /* border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #eceff5; */
}

@media screen and (max-width: 500px) {
    .investor-detail-header-mid {
        margin: 10px 0;
        align-items: center;
        justify-content: space-around;
    }

    .investor-detail-header-right {
        justify-content: space-between;
    }

    .investor-detail-header {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .investor-personal-detail {
        flex-direction: column;
    }

    .investor-personal-infos {
        grid-template-columns: auto;
    }

    .investor-detail-left {
        border-right: unset;
        padding-bottom: 10px !important;
    }

    .investor-info-parameter li {
        display: flex;
        flex-direction: row;
        list-style: none;
        padding: 5px 0;
    }

    .investor-detail-footer {
        flex-direction: column;
    }

    .investor-detail-footer div {
        width: 100%;
    }

    .investor-detail-blockrow {
        grid-template-columns: 100%;
    }

    .investor-detail-row .download-file {
        width: 100%;
    }
}
