.feed-wrapper {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 5px rgb(0 0 0 / 15%);
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.feed-header {
    padding: 10px 20px 10px 20px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    align-items: center;
}

.feed-image-container {
    padding: 0 20px;
}

.user-profile {
    display: flex;
    flex-direction: row;
}

.user-image {
    border-radius: 50%;
    object-fit: cover;
    height: 40px;
    width: 40px;
    margin-right: 10px;
}

.favourites {
    cursor: pointer;
}

.feed-body {
    padding: 20px;
}

.feed-description {
    font-size: 14px;
    max-height: 3em;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    margin-bottom: 10px;
}

.feed-head {
    padding: 20px 20px 0px 20px;
}

.feed-title {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
}

.idea-detail {
    font-size: 14px;
}

.detail-item {
    padding: 5px 0;
}

.feed-footer-btn {
    /* display: flex; */
    /* justify-content: space-between; */
    /* column-gap: 20px; */
    /* padding: 20px 10px; */
}

.feed-footer-btn a {
    text-decoration: none;
    width: 100%;
}

.feed-counter {
    /*border-top: 1px solid #ccc;*/
    /*border-bottom: 1px solid #ccc;*/
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    /*padding: 10px 20px;*/
}

.like-counter,
.views-counter {
    display: flex;
    align-items: center;
}

.feed-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 33%;
    justify-content: space-around;
    column-gap: 10px;
}

@media (min-width: 1000px){
	.feed-btn{
		width: 70%;
	}
}

.like-btn,
.favourite-btn,
.bookmark-btn {
    cursor: pointer;
}

.contact-btn {
    border-radius: 5px;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 500;
    letter-spacing: 1px;
    color: #fff;
    background-color: #09f;
    padding: 10px;
}

.contact-btn:hover {
    border-radius: 5px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
}

.add-new-idea button {
    border: none;
    border-radius: 5px;
    padding: 10px;
    background-color: #09f;
    color: #fff;
}

.idea-feed-block .idea_category {
    max-height: 80px;
    overflow: hidden;
}

.idea_category {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.feed-topbar-container {
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    padding: 15px;
}

.feed-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.idea-category-filter {
    /*margin-right: 20px;*/
    cursor: pointer;
}

.feed-topbar-container .idea_category {
    margin-top: 20px;
    display: inline-grid;
    grid-gap: 10px;
    grid-template: repeat(1, 1fr) / repeat(8, 1fr);
    grid-auto-flow: row; /* or 'row', 'row dense', 'column dense' */
}

.idea-feed-block {
    display: flex;
    padding: 10px 20px 0px 20px;
    /*align-items: center;*/
    justify-content: space-between;
}

.follow_investor {
    display: flex;
    align-items: center;
}

.idea-footer-left {
    width: 100%;
}

.idea-footer-right {
    /* display: flex;
    column-gap: 20px; */
}

@media screen and (max-width: 426px) {
    .follow_investor.idea.inner {
        flex: 1;
    }

    .feed-footer-btn {
        flex-direction: column-reverse;
        row-gap: 20px;
    }

    .idea-footer-right {
        justify-content: space-between;
    }

    .idea-detail .feed-counter {
        border-left: 2px solid #eceff5;
        border-radius: 5px !important;
    }

    .idea-detail {
        row-gap: 20px;
    }

    .feed-title {
        flex-direction: column;
    }
}
