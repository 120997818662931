
.top-title > h2 {
    margin-bottom: 0;
    color: #262b33;
}

.top-title-date {
    color: #74829b;
    font-size: 12px;
}

/* card Block */

.cardBlock-row {
    background-color: #fff;
    padding: 10px 5px;
    margin-bottom: 20px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr)); /*width*/
    width: 96%;
}

@media (min-width: 500px){
	.cardBlock-row{
		place-items: center;
	}
}

@media (max-width: 500px){
	.cardBlock-row{
		width: 92%;
	}
}

.cardBlock {
    background-color: #fff;

    color: #262b33;
    border: none;
    display: flex;
    align-items: center;

    padding: 0.5rem 0;
}

.cardBlock-center {
    justify-content: center;
}

.cardBlock-content {
    display: flex;
    align-items: center;
}

@media (max-width: 500px){
	.cardBlock-content{
		margin-left: 25px;
	}
}

.cardTitle {
    font-size: 12px;
    margin: 0;
    color: #74829b;
    font-weight: 500;
}

.cardIcon {
    font-size: 28px;
    border-radius: 10px;
    padding: 8px;
    display: flex;
    justify-content: center;
    margin-right: 15px;
}

.cardCount {
    font-size: 20px;
    font-weight: 600;
    color: #3f4d67;
}

.cardContent {
    display: flex;
    align-items: center;
    flex-direction: column;
}

/* end of Card Block */

/* screening block  */

.recent-activities {
    background-color: #fff;
    padding: 20px 0px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    list-style: none;
}

.activity-head {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    padding: 0 20px;
    margin-bottom: 15px;
}

.activities-list {
    list-style: none;
}

.top-lists,
.recent-screening-requests {
    padding-bottom: 20px;
}

.top-ideas-lists {
    list-style: numeric;
    background-color: #fff;
    padding: 20px 40px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.activity-icon {
    color: #fff;
    background-color: #ffc19e;
    font-size: 35px;
    border-radius: 10px;
    padding: 8px;
    margin-right: 10px;
}

.screening-item {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
}

.screening-item a:hover {
    text-decoration: none;
    color: #fff;
}

.screening-item:hover {
    background-color: #fcfcfc;
}

.view-idea-btn {
    background-color: #09f;
    border-radius: 5px;
    color: #fff;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 400;
}

/* end of Screening block  */

/* toplist */
.view-idea-btn:hover {
    background-color: #77bcfd;
    color: #fff;
    text-decoration: none;
}


.top-lists-container {
    background-color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    /*list-style: none;*/
}

.top-lists {
    /*list-style: none;*/
    padding: 0;
}

.top-lists-head {
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    font-weight: 500;
}

.top-list-item {
    /*background-color: #fcfcfc;*/
    font-size: 14px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px;
    cursor: pointer;
}

.top-list-item:hover {
    background-color: #fcfcfc;
}

.top-ideas-view-count {
    background-color: #eceff5;
    border-radius: 25px;
    padding: 5px 10px;
    font-size: 12px;
    color: #74829b;
    font-weight: 500;
}

/* end of top lists */

.chart {
    background-color: #fff;
    width: 100%;
    margin-bottom: 20px;
    padding: 20px 20px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.title {
    font-size: 1.3rem;
    font-weight: 700;
    white-space: nowrap;
    color: #3f4d67;
}

.menu-bars-mini {
    display: none;
}

.todays-activity {
    background-color: #fff;
    width: 100%;
    margin-bottom: 20px;
    padding: 20px 20px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.dropdown-footer {
    border-top: 1px solid #ddd;
    padding: 10px 0;
}
