.idea-form .personal-details {
    /* background-color: #eceff5; */
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.idea-form .personal-details h3 {
    width: 100%;
    font-weight: 500 !important;
}

.idea-form-parameter {
    width: calc(100% / 2 - 20px);
    margin-bottom: 10px;
}

.form-error {
    margin-top: 0.15em;
    float: right;
    color: red;
}

.idea-form-parameter input {
    background-color: #fff;
    border-radius: 5px;

    padding: 5px;
    font-size: 14px;
    width: 100%;
}

.idea-parameter {
    display: flex;
    width: calc(100% / 2 - 20px);
    justify-content: space-between;
}

.idea-location-parameter {
    display: flex;
    width: calc(90% - 20px);
    justify-content: space-between;
    width: 100%;
}

.idea-parameter .idea-form-parameter input,
.idea-location-parameter .idea-form-parameter input {
    background-color: #fff;
    border-radius: 5px;
    /* border: none; */
    padding: 5px;
    font-size: 14px;
    width: 100%;
}

.idea-location-parameter .idea-form-parameter input {
    width: 100%;
}

.idea-form-parameter input:focus {
    outline: none;
}

.idea-form-parameter label,
.idea-form-parameter span {
    font-size: 14px;
    margin-right: 0px;
}

.form-row {
    margin-bottom: 10px;
    display: flex;
    align-items: baseline;
}

.idea-form-parameter input[type="file"] {
    display: none;
}

.idea-form-parameter textarea {
    border-radius: 5px;
    border: none;
    background-color: #fff;
    outline: none;
    padding: 5px;
    width: 100%;
    border: 1px solid #cccc;
}

.upload-btn {
    flex-direction: unset !important;
}

.upload-btn label {
    border-radius: 5px;
    background-color: #fff;
    padding: 5px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.upload-btn label:active {
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.15);
}

.form-parameter {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.details {
    /* background-color: #eceff5; */
    border-radius: 10px;
}

.form-wrapper {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 5px 5px rgb(0 0 0 / 15%);
    min-height: 500px;
    /* width: 100%; */
    padding: 20px;
}

@media (min-width: 930px){
	.form-wrapper{
		margin: 0 20px 20px 20px;
	}
}

.form-wrapper .form-header {
    display: flex;
    position: relative;
    text-align: start;
    justify-content: space-between;
    flex-direction: column;
}

.form-detail-title {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}

.idea-form-parameter {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-body {
    margin-top: 5px !important;
}

.form-body .details {
    margin-bottom: 50px;
}

.form-btn {
    text-align: center;
}

.form-btn button {
    /*border: none;*/
    /*color: #fff;*/
    /*background-color: #09f;*/
    /*padding: 10px 20px;*/
    /*border-radius: 5px;*/
}

.form-header .close-btn {
    position: absolute;
    color: #09f;
    border: none;
    background-color: #fff;
    right: 10px;
}

.form-header .close-btn:hover {
    border-radius: 50%;
    background-color: #edeef1;
}

.header-title span {
    font-size: 14px;
    color: #878787 !important;
    font-weight: 300;
}

.header-title h2 {
    color: #09f !important;
    font-weight: 500 !important;
}

#idea-form .btn-secondary {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* outline: none; */
    padding: 6px;
    margin: unset;
    margin-bottom: 10px;
    background-color: #fff !important;
    border-radius: 4px;
    color: #000;
    box-shadow: none;
    border: 1px solid #c2c2c2;
}

.social-links-parameter {
    display: flex;
    width: 100%;
    column-gap: 20px;
}

/*.idea-form-parameter fieldset {*/
/*  border: unset;*/
/*}*/
/*.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {*/
/*  border: 1px solid #c2c2c2 !important;*/
/*}*/
/*.idea-form-parameter .MuiOutlinedInput-root {*/
/*  padding: unset !important;*/
/*}*/
.css-1nrlq1o-MuiFormControl-root,.css-viou3o-MuiAutocomplete-root{
    max-width: 100%;
}

@media (max-width: 584px) {
    .form-body {
        width: 100%;
    }

    .idea-form-parameter {
        width: 100%;
    }
}

.idea-form-parameter input[type="file"] {
    display: none;
}

@media (min-width: 300px){
.idea-form-parameter ul{
	overflow: visible;
		-ms-overflow-style: none;
		scrollbar-width: none;
		-webkit-scrollbar{
		display: none;
		}
	}
}