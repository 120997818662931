.chart-container {
    width: 60vw;
    margin: 20px auto;
    height: fit-content;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    background-color: #fff;
    padding: 20px 20px;
}

@media (max-width: 786px){
	.chart-container {
		width: 90vw;
	}
}