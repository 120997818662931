[class^="box-"] {
    display: grid;
}

#investor-page {
    margin: 0 10px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:
    "p p p p  p p p p  p p p p"
    "f f f f  f f f f  f f f f"
    "a a a a  a a a a  a a a a";
}

#investor-page .box-1 {
    grid-area: p;
    z-index: 99;
}

#investor-page .box-2 {
    grid-area: f;
}

#investor-page .box-3 {
    grid-area: a;
}

.recommendation-column .top-lists-container {
    position: sticky;
    top: 85px;
}

@media only screen and (min-width: 1024px) {
    #investor-page {
        display: grid;
        margin: unset;
        padding: 0px 40px;
        gap: 20px;
        grid-template-columns: repeat(12, 1fr);
        grid-template-areas: "p p p f f f f f f a a a";
    }

    #investor-page .box-1 {
        grid-area: p;
    }

    #investor-page .box-2 {
        grid-area: f;
    }

    #investor-page .box-3 {
        grid-area: a;
    }
}
